/* eslint-disable react/prop-types */
import InputEmail from "components/Input/InputEmail"
import InputPassword from "components/Input/InputPassword"
import InputText from "components/Input/InputText"
import { useFormik } from "formik"
import React, { useState } from "react"
import MetaTags from "react-meta-tags"
import { Link } from "react-router-dom"
import * as Yup from "yup"
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Modal,
  Container,
  Form,
  Alert,
  Spinner,
} from "reactstrap"
import { deleteApiAdmin } from "helpers/BackendHelper/kelola-admin-backend"
import { updateApiStatusBeasiswaRegister } from "helpers/BackendHelper/beasiswa_register_backend"

const DialogUpdateStatus = ({
  isOpen = false,
  onNo = () => {},
  onClose = () => {},
  dataUserBeasiswa = null,
  onRefreshTable = () => {},
}) => {
  const [error, setError] = useState("")
  const [success, setSuccess] = useState("")
  const [loading, setLoading] = useState()
  {
    error && error ? <Alert color="danger">Error: {error}</Alert> : null
  }

  const updateStatus = async () => {
    try {
      setLoading(true)
      const response = await updateApiStatusBeasiswaRegister(dataUserBeasiswa)
      setLoading(false)
      if (response.status === "SUCCESS") {
        onClose();
        onRefreshTable();
      } else {
        setError(response.message)
        setTimeout(() => {
          setError("")
        }, 5000)
      }
    } catch (error) {
      setLoading(false)
      setError("Something went wrong, please try again")
      setTimeout(() => {
        setError("")
      }, 5000)
    }
  }

  return (
    <Modal isOpen={isOpen}>
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
          Update Status
        </h5>
        <button
          type="button"
          onClick={loading ? () => {} : onClose}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        {error && error ? <Alert color="danger">Error: {error}</Alert> : null}

        <div className="text-center">
          <p className="h6 mb-4 d-block text-danger">
            Apakah kamu yakin akan merubah status?
          </p>
          <p className="h5 d-block">Nama : {dataUserBeasiswa?.name}</p>
          <p className="h5 d-block">Email : {dataUserBeasiswa?.email}</p>
          <p className="h5 d-block"><strong>{dataUserBeasiswa?.status_string}</strong> menjadi <strong>{dataUserBeasiswa?.status_string_update}</strong></p>
        </div>
      </div>
      <div className="modal-footer">
      <button className={`btn btn-danger btn-block ${loading ? 'disabled' : ''} me-2`} onClick={loading ? () => {} : () => onNo(dataUserBeasiswa?.status_update)}>
          No
        </button>
        <button className={`btn btn-danger btn-block ${loading ? 'disabled' : ''}`} onClick={loading ? () => {} : () => updateStatus()}>
          {loading ? <Spinner size="sm" /> : "Yes"}
        </button>
      </div>
    </Modal>
  )
}

export default DialogUpdateStatus
