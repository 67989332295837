/* eslint-disable react/display-name */
import React, { useEffect, useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Button,
  Spinner,
} from "reactstrap"
import MetaTags from "react-meta-tags"

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import "../../assets/scss/datatables.scss"
import { getApiUser } from "helpers/BackendHelper/kelola-user-backend"
import { getApiRegisterBeasiswa } from "helpers/BackendHelper/pengajuan-beasiswa-backend"
import { Link } from "react-router-dom"
import InputSingleSelect from "components/Input/InputSingleSelect"
import { useFormik } from "formik"
import DialogUpdateStatus from "./dialog-update-status"

const PengajuanBeasiswaPage = () => {
  const [limit, setLimit] = useState(10)
  const [offset, setOffset] = useState()
  const [isFormOpen, setFormOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [dataUserBeasiswa, setDataUserBeasiswa] = useState(false)
  const [isDialogStatusOpen, setDialogStatusOpen] = useState(false)

  const _formik = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: false,

    initialValues: {
      status: "",
    },
  })

  const optionsStatus = [
    { label: "Lolos Seleksi", value: "pass" },
    { label: "Tidak Lolos", value: "fail" },
    { label: "Sudah Submit", value: "submit" },
  ]



  useEffect(() => {
    handleTableChange("page", { page: 1, sizePerPage: limit })
  }, [])
  const columns = [
    {
      dataField: "name",
      text: "Name",
      sort: true,
    },
    {
      dataField: "email",
      text: "Email",
      sort: true,
    },
    {
      dataField: "score",
      text: "Score",
      sort: true,
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
      width: "10%",
      formatter: (cell, row) => {
        if (row.status !== "draft")
          return (
            <div style={{ width: "150px" }}>
              <InputSingleSelect
                name="status"
                options={optionsStatus}
                value={
                  optionsStatus
                    ? optionsStatus.find(option => option.value === row.status)
                    : ""
                }
                onChange={option => {
                  if (row.status !== option.value)
                    showUpdateStatus(row, option.value, option.label)
                }}
                onBlur={_formik.handleBlur}
              />
            </div>
          )
        else {
          return <React.Fragment>{row.status_string}</React.Fragment>
        }
      },
    },
    {
      dataField: "id",
      text: "Action",
      sort: false,
      formatter: row => {
        return (
          <div>
            <Link
              to={`/detail-pengajuan-beasiswa/${row}`}
              className={`btn btn-primary btn-block btn-sm me-2`}
            >
              Detail
            </Link>
            <Link
              to={`/score-pengajuan-beasiswa/${row}`}
              className={`btn btn-primary btn-block btn-sm me-2`}
            >
              Score
            </Link>
          </div>
        )
      },
    },
  ]

  const [productData, setProductData] = useState([])
  const [pageOptions, setPageOptions] = useState({
    page: 1,
    sizePerPage: limit,
    totalSize: 0,
    custom: true,
  })

  const handleTableChange = async (type, { page, sizePerPage, searchText, sortField, sortOrder }) => {
    const _offset = (page - 1) * limit

    let sort = "";
    if(!searchText)
      searchText = "";

    if(sortField)
      sort = `&order_name=${sortField}&order_dir=${sortOrder}`;

    try {
      setLoading(true)
      const response = await getApiRegisterBeasiswa(_offset, limit, searchText, sort)
      setLoading(false)
      if (response.status === "SUCCESS") {

        setOffset((page - 1) * limit)
        setProductData(response.data)
        setPageOptions({
          ...pageOptions,
          page: page,
          totalSize: response.data_total,
        })
      } else {
      }
    } catch (error) {}
  }

  const onRefreshTable = async () => {
    try {
      setLoading(true)
      const response = await getApiRegisterBeasiswa(offset, limit)
      setLoading(false)
      if (response.status === "SUCCESS") {
        setProductData(response.data)
      } else {
      }
    } catch (error) {}
  }

  const showUpdateStatus = (row, statusUpdate, statusStringUpdate) => {
    setDataUserBeasiswa({
      ...row,
      status_update: statusUpdate,
      status_string_update: statusStringUpdate,
    })
    setDialogStatusOpen(true)
  }

  const { SearchBar } = Search;

  return (
    <React.Fragment>
      <DialogUpdateStatus
        dataUserBeasiswa={dataUserBeasiswa}
        isOpen={isDialogStatusOpen}
        onClose={() => {
          setDialogStatusOpen(false)
        }}
        onRefreshTable={() => onRefreshTable()}
        onNo={statusUpdate => {
          setDialogStatusOpen(false)
        }}
      />
      <div className="page-content">
        <MetaTags>
          <title>Pengajuan Beasiswa | CMS - Beasiswa BUMN</title>
        </MetaTags>
        <div className="container-fluid">
          <Breadcrumbs
            title="Pengajuan Beasiswa"
            breadcrumbItem=""
            titleMenu="Pengajuan Beasiswa"
          />

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField="id"
                    columns={columns}
                    data={productData}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        bootstrap4
                        keyField="id"
                        columns={columns}
                        data={productData}
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <Row className="mb-2">
                              <Col md="4">
                                <div className="search-box me-2 mb-2 d-inline-block">
                                  <div className="position-relative">
                                    <SearchBar {...toolkitProps.searchProps} />
                                    <i className="bx bx-search-alt search-icon" />
                                  </div>
                                </div>
                              </Col>
                            </Row>

                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                  bootstrap4
                                    remote={ { search: true, sort: true, pagination: true } }
                                    keyField={"id"}
                                    responsive
                                    bordered={false}
                                    striped={true}
                                    classes={"table align-middle table-nowrap"}
                                    headerWrapperClasses={"thead-light"}
                                    loading={true} //only loading is true, react-bootstrap-table will render overlay
                                    onTableChange={handleTableChange}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                  />
                                </div>
                              </Col>
                            </Row>

                            <Row className="align-items-md-center mt-30">
                              <Col className="inner-custom-pagination d-flex">
                                <div className="text-md-right ms-auto">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                  {loading && (
                    <div>
                      <Spinner size={"sm"} className="me-2" />{" "}
                      <span>Please wait, Get Data...</span>
                    </div>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default PengajuanBeasiswaPage
