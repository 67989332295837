/* eslint-disable react/prop-types */

import InputText from "components/Input/InputText"
import { useFormik } from "formik"
import * as Yup from "yup"
import React, { useEffect, useState } from "react"
import {
  Alert,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  Label,
  Row,
  Spinner,
} from "reactstrap"
import InputFile from "components/Input/InputFile"
import InputSingleSelect from "components/Input/InputSingleSelect"
import InputDateMask from "components/Input/InputDateMask"
import InputTextArea from "components/Input/InputTextArea"
import InputEmail from "components/Input/InputEmail"
import InputNumber from "components/Input/InputNumber"
import { Link, useHistory } from "react-router-dom"
import {
  getAPIScoreBeasiswaRegister,
  postAPIScoreBeasiswaRegister,
} from "helpers/BackendHelper/beasiswa_register_backend"
import { scrollBottom, scrollTop } from "helpers/utils_helper"
import { MetaTags } from "react-meta-tags"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import InputTextSingleLine from "components/Input/InputTextSingleLine"

const ScorePengajuanBeasiswa = props => {
  const [loading, setLoading] = useState()
  const history = useHistory()
  const [idRegister, setIdRegister] = useState(null);
  const [dataScore, setDataScore] = useState(null);
  const [error, setError] = useState("")

  useEffect(() => {
    setIdRegister(props.match.params.id)
    getScoreBeasiswaRegister(props.match.params.id)
  }, [])


  const getScoreBeasiswaRegister = async id => {
    try {
      const response = await getAPIScoreBeasiswaRegister(id)
      if (response.status === "SUCCESS") {
        setDataScore(response.data)
      } else {
      }
    } catch (error) {}
  }


  const _formik = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      score_1a: dataScore?.score_1a || "",
      score_1b: dataScore?.score_1b || "",
      score_1c: dataScore?.score_1c || "",

      score_2a: dataScore?.score_2a || "",
      score_2b: dataScore?.score_2b || "",
      score_2c: dataScore?.score_2c || "",
      score_2d: dataScore?.score_2d || "",
      score_2e: dataScore?.score_2e || "",
      score_2f: dataScore?.score_2f|| "",

      score_3a: dataScore?.score_3a || "",
      score_3b: dataScore?.score_3b || "",
      score_3c: dataScore?.score_3c || "",
      score_3d: dataScore?.score_3d || "",
      score_3e: dataScore?.score_3e || "",
      score_3f: dataScore?.score_3f || "",

      score_4a: dataScore?.score_4a || "",

      score_5a: dataScore?.score_5a || "",
      score_5b: dataScore?.score_5b || "",
      score_5c: dataScore?.score_5c || "",

      score_6a: dataScore?.score_6a || "",
      score_6b: dataScore?.score_6b || "",

      score_7a: dataScore?.score_7a || "",
      score_7b: dataScore?.score_7b || "",

      score_8a: dataScore?.score_8a || "",

      score_9a: dataScore?.score_9a || "",
      score_9b: dataScore?.score_9b || "",
      score_9c: dataScore?.score_9c || "",

      score_10a: dataScore?.score_10a || "",
      score_10b: dataScore?.score_10b || "",
      score_10c: dataScore?.score_10c || "",
      score_10d: dataScore?.score_10d || "",

      score_11a: dataScore?.score_11a || "",
      score_11b: dataScore?.score_11b || "",
      score_11c: dataScore?.score_11c || "",
      score_11d: dataScore?.score_11d || "",
      score_11e: dataScore?.score_11e || "",
      score_11f: dataScore?.score_11f || "",

      score_12a: dataScore?.score_12a || "",
      score_12b: dataScore?.score_12b || "",
      score_12c: dataScore?.score_12c || "",
      score_12d: dataScore?.score_12d || "",
      score_12e: dataScore?.score_12e || "",

      score_13a: dataScore?.score_13a || "",
      score_13b: dataScore?.score_13b || "",
      score_13c: dataScore?.score_13c || "",
    },

    validationSchema: Yup.object({
      score_1a: Yup.string().test("correct", "input 0-10", value => validateNumber(value)),
      score_1b: Yup.string().test("correct", "input 0-10", value => validateNumber(value)),
      score_1c: Yup.string().test("correct", "input 0-10", value => validateNumber(value)),

      score_2a: Yup.string().test("correct", "input 0-10", value => validateNumber(value)),
      score_2b: Yup.string().test("correct", "input 0-10", value => validateNumber(value)),
      score_2c: Yup.string().test("correct", "input 0-10", value => validateNumber(value)),
      score_2d: Yup.string().test("correct", "input 0-10", value => validateNumber(value)),
      score_2e: Yup.string().test("correct", "input 0-10", value => validateNumber(value)),
      score_2f: Yup.string().test("correct", "input 0-10", value => validateNumber(value)),

      score_3a: Yup.string().test("correct", "input 0-10", value => validateNumber(value)),
      score_3b: Yup.string().test("correct", "input 0-10", value => validateNumber(value)),
      score_3c: Yup.string().test("correct", "input 0-10", value => validateNumber(value)),
      score_3d: Yup.string().test("correct", "input 0-10", value => validateNumber(value)),
      score_3e: Yup.string().test("correct", "input 0-10", value => validateNumber(value)),
      score_3f: Yup.string().test("correct", "input 0-10", value => validateNumber(value)),

      score_4a: Yup.string().test("correct", "input 0-10", value => validateNumber(value)),

      score_5a: Yup.string().test("correct", "input 0-10", value => validateNumber(value)),
      score_5b: Yup.string().test("correct", "input 0-10", value => validateNumber(value)),
      score_5c: Yup.string().test("correct", "input 0-10", value => validateNumber(value)),

      score_6a: Yup.string().test("correct", "input 0-10", value => validateNumber(value)),
      score_6b: Yup.string().test("correct", "input 0-10", value => validateNumber(value)),

      score_7a: Yup.string().test("correct", "input 0-10", value => validateNumber(value)),
      score_7b: Yup.string().test("correct", "input 0-10", value => validateNumber(value)),

      score_8a: Yup.string().test("correct", "input 0-10", value => validateNumber(value)),

      score_9a: Yup.string().test("correct", "input 0-10", value => validateNumber(value)),
      score_9b: Yup.string().test("correct", "input 0-10", value => validateNumber(value)),
      score_9c: Yup.string().test("correct", "input 0-10", value => validateNumber(value)),

      score_10a: Yup.string().test("correct", "input 0-10", value => validateNumber(value)),
      score_10b: Yup.string().test("correct", "input 0-10", value => validateNumber(value)),
      score_10c: Yup.string().test("correct", "input 0-10", value => validateNumber(value)),
      score_10d: Yup.string().test("correct", "input 0-10", value => validateNumber(value)),

      score_11a: Yup.string().test("correct", "input 0-10", value => validateNumber(value)),
      score_11b: Yup.string().test("correct", "input 0-10", value => validateNumber(value)),
      score_11c: Yup.string().test("correct", "input 0-10", value => validateNumber(value)),
      score_11d: Yup.string().test("correct", "input 0-10", value => validateNumber(value)),
      score_11e: Yup.string().test("correct", "input 0-10", value => validateNumber(value)),
      score_11f: Yup.string().test("correct", "input 0-10", value => validateNumber(value)),

      score_12a: Yup.string().test("correct", "input 0-10", value => validateNumber(value)),
      score_12b: Yup.string().test("correct", "input 0-10", value => validateNumber(value)),
      score_12c: Yup.string().test("correct", "input 0-10", value => validateNumber(value)),
      score_12d: Yup.string().test("correct", "input 0-10", value => validateNumber(value)),
      score_12e: Yup.string().test("correct", "input 0-10", value => validateNumber(value)),

      score_13a: Yup.string().test("correct", "input 0-10", value => validateNumber(value)),
      score_13b: Yup.string().test("correct", "input 0-10", value => validateNumber(value)),
      score_13c: Yup.string().test("correct", "input 0-10", value => validateNumber(value)),
    }),

    onSubmit: values => {
      values = {
        ...values,
        id_register: idRegister,
      }
    
      if (!loading) updateScore(values)
    },
  })

  const validateNumber = (value) => {
    if(isPositiveInteger(value)){
      if((parseInt(value) >= 0 && parseInt(value) <= 10))
        return true;
      else
        return false;
    }
    else if(value === undefined){
      return true;
    }
    else{
      return false;
    }
  }


  const isPositiveInteger = (str) => {
    if (typeof str !== 'string') {
      return false;
    }
  
    const num = Number(str);
  
    if (Number.isInteger(num) && num >= 0) {
      return true;
    }
  
    return false;
  }

  const updateScore = async data => {
    setLoading(true)
    try {
      const response = await postAPIScoreBeasiswaRegister(data)
      setLoading(false)
      if (response.status === "SUCCESS") {
        history.push("/pengajuan-beasiswa")
      } else {
        setError(response.message)
        setTimeout(() => {
          setError("")
        }, 5000)
      }
    } catch (error) {
      setError("Something went wrong, please try again")
      setTimeout(() => {
        setError("")
      }, 5000)
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>
          Score Pengajuan Beasiswa | CMS - Beasiswa BUMN
          </title>
        </MetaTags>
        <Container fluid={true}>
          <Breadcrumbs
            title="Pengajuan Beasiswa"
            breadcrumbItem="Score"
            titleMenu="Score"
          />
          <Form
            className="form-horizontal"
            onSubmit={e => {
              e.preventDefault()
              _formik.handleSubmit()
              return false
            }}
          >
            <Row>
              <Col>
                <Card>
                  <CardBody>
                    {error && error ? (
                      <Alert color="danger">Error: {error}</Alert>
                    ) : null}

                    <Label className="h5">1. PERSONAL INFORMATION</Label>
                    <InputTextSingleLine
                      label="Applicants is a woman (Woman has more score than man)"
                      name="score_1a"
                      placeholder="0-10"
                      value={_formik.values.score_1a || ""}
                      onChange={_formik.handleChange}
                      onBlur={_formik.handleBlur}
                      invalid={
                        _formik.touched.score_1a && _formik.errors.score_1a
                          ? true
                          : false
                      }
                      errorMsg={_formik.errors.score_1a}
                    />
                    <InputTextSingleLine
                      label="Applicants comes from outside Java (Has more score than inside Java)"
                      name="score_1b"
                      placeholder="0-10"
                      value={_formik.values.score_1b || ""}
                      onChange={_formik.handleChange}
                      onBlur={_formik.handleBlur}
                      invalid={
                        _formik.touched.score_1b && _formik.errors.score_1b
                          ? true
                          : false
                      }
                      errorMsg={_formik.errors.score_1b}
                    />
                    <InputTextSingleLine
                      label="Applicants has disabilities (Has more score)"
                      name="score_1c"
                      placeholder="0-10"
                      value={_formik.values.score_1c || ""}
                      onChange={_formik.handleChange}
                      onBlur={_formik.handleBlur}
                      invalid={
                        _formik.touched.score_1c && _formik.errors.score_1c
                          ? true
                          : false
                      }
                      errorMsg={_formik.errors.score_1c}
                    />

                    <hr />

                    <Label className="h5">
                      2. ENGLISH LANGUAGE PROFICIENCY
                    </Label>
                    <InputTextSingleLine
                      label="Applicants has TOEFL 500 - 525"
                      name="score_2a"
                      placeholder="0-10"
                      value={_formik.values.score_2a || ""}
                      onChange={_formik.handleChange}
                      onBlur={_formik.handleBlur}
                      invalid={
                        _formik.touched.score_2a && _formik.errors.score_2a
                          ? true
                          : false
                      }
                      errorMsg={_formik.errors.score_2a}
                    />
                    <InputTextSingleLine
                      label="Applicants has TOEFL 526 - 550"
                      name="score_2b"
                      placeholder="0-10"
                      value={_formik.values.score_2b || ""}
                      onChange={_formik.handleChange}
                      onBlur={_formik.handleBlur}
                      invalid={
                        _formik.touched.score_2b && _formik.errors.score_2b
                          ? true
                          : false
                      }
                      errorMsg={_formik.errors.score_2b}
                    />
                    <InputTextSingleLine
                      label="Applicants has TOEFL > 550"
                      name="score_2c"
                      placeholder="0-10"
                      value={_formik.values.score_2c || ""}
                      onChange={_formik.handleChange}
                      onBlur={_formik.handleBlur}
                      invalid={
                        _formik.touched.score_2c && _formik.errors.score_2c
                          ? true
                          : false
                      }
                      errorMsg={_formik.errors.score_2c}
                    />
                    <InputTextSingleLine
                      label="Applicants has IELTS > 6.5"
                      name="score_2d"
                      placeholder="0-10"
                      value={_formik.values.score_2d || ""}
                      onChange={_formik.handleChange}
                      onBlur={_formik.handleBlur}
                      invalid={
                        _formik.touched.score_2d && _formik.errors.score_2d
                          ? true
                          : false
                      }
                      errorMsg={_formik.errors.score_2d}
                    />
                    <InputTextSingleLine
                      label="Applicants has iBT > 60"
                      name="score_2e"
                      placeholder="0-10"
                      value={_formik.values.score_2e || ""}
                      onChange={_formik.handleChange}
                      onBlur={_formik.handleBlur}
                      invalid={
                        _formik.touched.score_2e && _formik.errors.score_2e
                          ? true
                          : false
                      }
                      errorMsg={_formik.errors.score_2e}
                    />
                    <InputTextSingleLine
                      label="Test date must be on September 2020 onward"
                      name="score_2f"
                      placeholder="0-10"
                      value={_formik.values.score_2f || ""}
                      onChange={_formik.handleChange}
                      onBlur={_formik.handleBlur}
                      invalid={
                        _formik.touched.score_2f && _formik.errors.score_2f
                          ? true
                          : false
                      }
                      errorMsg={_formik.errors.score_2f}
                    />

                    <hr />

                    <Label className="h5">3. EDUCATIONAL BACKGROUND</Label>
                    <InputTextSingleLine
                      label="Applicants has GPA 3.0 - 3.25"
                      name="score_3a"
                      placeholder="0-10"
                      value={_formik.values.score_3a || ""}
                      onChange={_formik.handleChange}
                      onBlur={_formik.handleBlur}
                      invalid={
                        _formik.touched.score_3a && _formik.errors.score_3a
                          ? true
                          : false
                      }
                      errorMsg={_formik.errors.score_3a}
                    />
                    <InputTextSingleLine
                      label="Applicants has GPA 3.26 - 3.50"
                      name="score_3b"
                      placeholder="0-10"
                      value={_formik.values.score_3b || ""}
                      onChange={_formik.handleChange}
                      onBlur={_formik.handleBlur}
                      invalid={
                        _formik.touched.score_3b && _formik.errors.score_3b
                          ? true
                          : false
                      }
                      errorMsg={_formik.errors.score_3b}
                    />
                    <InputTextSingleLine
                      label="Applicants has GPA 3.51 - 3.75"
                      name="score_3c"
                      placeholder="0-10"
                      value={_formik.values.score_3c || ""}
                      onChange={_formik.handleChange}
                      onBlur={_formik.handleBlur}
                      invalid={
                        _formik.touched.score_3c && _formik.errors.score_3c
                          ? true
                          : false
                      }
                      errorMsg={_formik.errors.score_3c}
                    />
                    <InputTextSingleLine
                      label="Applicants has GPA > 3.75"
                      name="score_3d"
                      placeholder="0-10"
                      value={_formik.values.score_3d || ""}
                      onChange={_formik.handleChange}
                      onBlur={_formik.handleBlur}
                      invalid={
                        _formik.touched.score_3d && _formik.errors.score_3d
                          ? true
                          : false
                      }
                      errorMsg={_formik.errors.score_3d}
                    />
                    <InputTextSingleLine
                      label="Applicants background education is in line with area of study"
                      name="score_3e"
                      placeholder="0-10"
                      value={_formik.values.score_3e || ""}
                      onChange={_formik.handleChange}
                      onBlur={_formik.handleBlur}
                      invalid={
                        _formik.touched.score_3e && _formik.errors.score_3e
                          ? true
                          : false
                      }
                      errorMsg={_formik.errors.score_3e}
                    />
                    <InputTextSingleLine
                      label="Applicant do not have or ongoing study Master's Degree"
                      name="score_3f"
                      placeholder="0-10"
                      value={_formik.values.score_3f || ""}
                      onChange={_formik.handleChange}
                      onBlur={_formik.handleBlur}
                      invalid={
                        _formik.touched.score_3f && _formik.errors.score_3f
                          ? true
                          : false
                      }
                      errorMsg={_formik.errors.score_3f}
                    />

                    <hr />

                    <Label className="h5">4. SCHOLARSHIP HISTORY</Label>
                    <InputTextSingleLine
                      label="Applicants received scholarship for excellent performance of study/project or exchange study"
                      name="score_4a"
                      placeholder="0-10"
                      value={_formik.values.score_4a || ""}
                      onChange={_formik.handleChange}
                      onBlur={_formik.handleBlur}
                      invalid={
                        _formik.touched.score_4a && _formik.errors.score_4a
                          ? true
                          : false
                      }
                      errorMsg={_formik.errors.score_4a}
                    />

                    <hr />

                    <Label className="h5">5. EMPLOYMENT HISTORY</Label>
                    <InputTextSingleLine
                      label="Applicants has strategic position in the office"
                      name="score_5a"
                      placeholder="0-10"
                      value={_formik.values.score_5a || ""}
                      onChange={_formik.handleChange}
                      onBlur={_formik.handleBlur}
                      invalid={
                        _formik.touched.score_5a && _formik.errors.score_5a
                          ? true
                          : false
                      }
                      errorMsg={_formik.errors.score_5a}
                    />
                    <InputTextSingleLine
                      label="Applicants has sufficient experience to support his/her current job"
                      name="score_5b"
                      placeholder="0-10"
                      value={_formik.values.score_5b || ""}
                      onChange={_formik.handleChange}
                      onBlur={_formik.handleBlur}
                      invalid={
                        _formik.touched.score_5b && _formik.errors.score_5b
                          ? true
                          : false
                      }
                      errorMsg={_formik.errors.score_5b}
                    />
                    <InputTextSingleLine
                      label="Applicants has 5-year experience working in BUMN"
                      name="score_5c"
                      placeholder="0-10"
                      value={_formik.values.score_5c || ""}
                      onChange={_formik.handleChange}
                      onBlur={_formik.handleBlur}
                      invalid={
                        _formik.touched.score_5c && _formik.errors.score_5c
                          ? true
                          : false
                      }
                      errorMsg={_formik.errors.score_5c}
                    />

                    <hr />

                    <Label className="h5">
                      6. COMMUNITY SERVICE ACTIVITIES
                    </Label>
                    <InputTextSingleLine
                      label="Applicants involved in community that related to his/her job"
                      name="score_6a"
                      placeholder="0-10"
                      value={_formik.values.score_6a || ""}
                      onChange={_formik.handleChange}
                      onBlur={_formik.handleBlur}
                      invalid={
                        _formik.touched.score_6a && _formik.errors.score_6a
                          ? true
                          : false
                      }
                      errorMsg={_formik.errors.score_6a}
                    />
                    <InputTextSingleLine
                      label="Applicants has important role in his/her community"
                      name="score_6b"
                      placeholder="0-10"
                      value={_formik.values.score_6b || ""}
                      onChange={_formik.handleChange}
                      onBlur={_formik.handleBlur}
                      invalid={
                        _formik.touched.score_6b && _formik.errors.score_6b
                          ? true
                          : false
                      }
                      errorMsg={_formik.errors.score_6b}
                    />

                    <hr />

                    <Label className="h5">7. PUBLICATIONS</Label>
                    <InputTextSingleLine
                      label="Applicants has published articles/journal or his/her writing"
                      name="score_7a"
                      placeholder="0-10"
                      value={_formik.values.score_7a || ""}
                      onChange={_formik.handleChange}
                      onBlur={_formik.handleBlur}
                      invalid={
                        _formik.touched.score_7a && _formik.errors.score_7a
                          ? true
                          : false
                      }
                      errorMsg={_formik.errors.score_7a}
                    />
                    <InputTextSingleLine
                      label="Applicant writing is related to his/her job"
                      name="score_7b"
                      placeholder="0-10"
                      value={_formik.values.score_7b || ""}
                      onChange={_formik.handleChange}
                      onBlur={_formik.handleBlur}
                      invalid={
                        _formik.touched.score_7b && _formik.errors.score_7b
                          ? true
                          : false
                      }
                      errorMsg={_formik.errors.score_7b}
                    />

                    <hr />

                    <Label className="h5">8. AWARDS</Label>
                    <InputTextSingleLine
                      label="Applicants received award related to his/her job"
                      name="score_8a"
                      placeholder="0-10"
                      value={_formik.values.score_8a || ""}
                      onChange={_formik.handleChange}
                      onBlur={_formik.handleBlur}
                      invalid={
                        _formik.touched.score_8a && _formik.errors.score_8a
                          ? true
                          : false
                      }
                      errorMsg={_formik.errors.score_8a}
                    />

                    <hr />

                    <Label className="h5">9. FIELD OF STUDY</Label>
                    <InputTextSingleLine
                      label="Applicant selection field of study is in line with applicant educational background"
                      name="score_9a"
                      placeholder="0-10"
                      value={_formik.values.score_9a || ""}
                      onChange={_formik.handleChange}
                      onBlur={_formik.handleBlur}
                      invalid={
                        _formik.touched.score_9a && _formik.errors.score_9a
                          ? true
                          : false
                      }
                      errorMsg={_formik.errors.score_9a}
                    />
                    <InputTextSingleLine
                      label="Applicant field of study is in-line with applicant job responsibility"
                      name="score_9b"
                      placeholder="0-10"
                      value={_formik.values.score_9b || ""}
                      onChange={_formik.handleChange}
                      onBlur={_formik.handleBlur}
                      invalid={
                        _formik.touched.score_9b && _formik.errors.score_9b
                          ? true
                          : false
                      }
                      errorMsg={_formik.errors.score_9b}
                    />
                    <InputTextSingleLine
                      label="Applicant express his/her field of study with solid reason"
                      name="score_9c"
                      placeholder="0-10"
                      value={_formik.values.score_9c || ""}
                      onChange={_formik.handleChange}
                      onBlur={_formik.handleBlur}
                      invalid={
                        _formik.touched.score_9c && _formik.errors.score_9c
                          ? true
                          : false
                      }
                      errorMsg={_formik.errors.score_9c}
                    />

                    <hr />

                    <Label className="h5">10. UNIVERSITY PREFERENCE</Label>
                    <InputTextSingleLine
                      label="Applicant has unconditional fulltime student LoA"
                      name="score_10a"
                      placeholder="0-10"
                      value={_formik.values.score_10a || ""}
                      onChange={_formik.handleChange}
                      onBlur={_formik.handleBlur}
                      invalid={
                        _formik.touched.score_10a && _formik.errors.score_10a
                          ? true
                          : false
                      }
                      errorMsg={_formik.errors.score_10a}
                    />

                    <InputTextSingleLine
                      label="Applicant university preference has matched curriculum or design program with his/her study needs"
                      name="score_10b"
                      placeholder="0-10"
                      value={_formik.values.score_10b || ""}
                      onChange={_formik.handleChange}
                      onBlur={_formik.handleBlur}
                      invalid={
                        _formik.touched.score_10b && _formik.errors.score_10b
                          ? true
                          : false
                      }
                      errorMsg={_formik.errors.score_10b}
                    />

                    <InputTextSingleLine
                      label="Applicant is in waiting for LoA"
                      name="score_10c"
                      placeholder="0-10"
                      value={_formik.values.score_10c || ""}
                      onChange={_formik.handleChange}
                      onBlur={_formik.handleBlur}
                      invalid={
                        _formik.touched.score_10c && _formik.errors.score_10c
                          ? true
                          : false
                      }
                      errorMsg={_formik.errors.score_10c}
                    />

                    <InputTextSingleLine
                      label="Applicant has conditional LoA"
                      name="score_10d"
                      placeholder="0-10"
                      value={_formik.values.score_10d || ""}
                      onChange={_formik.handleChange}
                      onBlur={_formik.handleBlur}
                      invalid={
                        _formik.touched.score_10d && _formik.errors.score_10d
                          ? true
                          : false
                      }
                      errorMsg={_formik.errors.score_10d}
                    />

                    <hr />

                    <Label className="h5">11. ESSAYS</Label>
                    <InputTextSingleLine
                      label="Strength and weakness"
                      name="score_11a"
                      placeholder="0-10"
                      value={_formik.values.score_11a || ""}
                      onChange={_formik.handleChange}
                      onBlur={_formik.handleBlur}
                      invalid={
                        _formik.touched.score_11a && _formik.errors.score_11a
                          ? true
                          : false
                      }
                      errorMsg={_formik.errors.score_11a}
                    />
                    <InputTextSingleLine
                      label="Challenge to overcame pursuing professional development"
                      name="score_11b"
                      placeholder="0-10"
                      value={_formik.values.score_11b || ""}
                      onChange={_formik.handleChange}
                      onBlur={_formik.handleBlur}
                      invalid={
                        _formik.touched.score_11b && _formik.errors.score_11b
                          ? true
                          : false
                      }
                      errorMsg={_formik.errors.score_11b}
                    />
                    <InputTextSingleLine
                      label="Leadership skills"
                      name="score_11c"
                      placeholder="0-10"
                      value={_formik.values.score_11c || ""}
                      onChange={_formik.handleChange}
                      onBlur={_formik.handleBlur}
                      invalid={
                        _formik.touched.score_11c && _formik.errors.score_11c
                          ? true
                          : false
                      }
                      errorMsg={_formik.errors.score_11c}
                    />
                    <InputTextSingleLine
                      label="Plan to addressed study challenge"
                      name="score_11d"
                      placeholder="0-10"
                      value={_formik.values.score_11d || ""}
                      onChange={_formik.handleChange}
                      onBlur={_formik.handleBlur}
                      invalid={
                        _formik.touched.score_11d && _formik.errors.score_11d
                          ? true
                          : false
                      }
                      errorMsg={_formik.errors.score_11d}
                    />
                    <InputTextSingleLine
                      label="Topic study is related to his/her current work or BUMN objectives"
                      name="score_11e"
                      placeholder="0-10"
                      value={_formik.values.score_11e || ""}
                      onChange={_formik.handleChange}
                      onBlur={_formik.handleBlur}
                      invalid={
                        _formik.touched.score_11e && _formik.errors.score_11e
                          ? true
                          : false
                      }
                      errorMsg={_formik.errors.score_11e}
                    />
                    <InputTextSingleLine
                      label="Current issue in professional/general issue in work"
                      name="score_11f"
                      placeholder="0-10"
                      value={_formik.values.score_11f || ""}
                      onChange={_formik.handleChange}
                      onBlur={_formik.handleBlur}
                      invalid={
                        _formik.touched.score_11f && _formik.errors.score_11f
                          ? true
                          : false
                      }
                      errorMsg={_formik.errors.score_11f}
                    />

                    <hr />

                    <Label className="h5">12. REFERENCE</Label>
                    <InputTextSingleLine
                      label="Referee describe applicant strength and weakness clearly"
                      name="score_12a"
                      placeholder="0-10"
                      value={_formik.values.score_12a || ""}
                      onChange={_formik.handleChange}
                      onBlur={_formik.handleBlur}
                      invalid={
                        _formik.touched.score_12a && _formik.errors.score_12a
                          ? true
                          : false
                      }
                      errorMsg={_formik.errors.score_12a}
                    />
                    <InputTextSingleLine
                      label="Referee describes applicant role in work showed applicant leadership"
                      name="score_12b"
                      placeholder="0-10"
                      value={_formik.values.score_12b || ""}
                      onChange={_formik.handleChange}
                      onBlur={_formik.handleBlur}
                      invalid={
                        _formik.touched.score_12b && _formik.errors.score_12b
                          ? true
                          : false
                      }
                      errorMsg={_formik.errors.score_12b}
                    />
                    <InputTextSingleLine
                      label="Referee describes applicant ability to solve the problem"
                      name="score_12c"
                      placeholder="0-10"
                      value={_formik.values.score_12c || ""}
                      onChange={_formik.handleChange}
                      onBlur={_formik.handleBlur}
                      invalid={
                        _formik.touched.score_12c && _formik.errors.score_12c
                          ? true
                          : false
                      }
                      errorMsg={_formik.errors.score_12c}
                    />
                    <InputTextSingleLine
                      label="Referee describes applicant initiative in related to event/work/community"
                      name="score_12d"
                      placeholder="0-10"
                      value={_formik.values.score_12d || ""}
                      onChange={_formik.handleChange}
                      onBlur={_formik.handleBlur}
                      invalid={
                        _formik.touched.score_12d && _formik.errors.score_12d
                          ? true
                          : false
                      }
                      errorMsg={_formik.errors.score_12d}
                    />
                    <InputTextSingleLine
                      label="Referee has positive comments or facts regarding applicants in academic/work or community related"
                      name="score_12e"
                      placeholder="0-10"
                      value={_formik.values.score_12e || ""}
                      onChange={_formik.handleChange}
                      onBlur={_formik.handleBlur}
                      invalid={
                        _formik.touched.score_12e && _formik.errors.score_12e
                          ? true
                          : false
                      }
                      errorMsg={_formik.errors.score_12e}
                    />

                    <hr />

                    <Label className="h5">13. EMPLOYER RECOMMENDATIONS</Label>
                    <InputTextSingleLine
                      label="Employer stated that field of study is matched with employer objectives"
                      name="score_13a"
                      placeholder="0-10"
                      value={_formik.values.score_13a || ""}
                      onChange={_formik.handleChange}
                      onBlur={_formik.handleBlur}
                      invalid={
                        _formik.touched.score_13a && _formik.errors.score_13a
                          ? true
                          : false
                      }
                      errorMsg={_formik.errors.score_13a}
                    />
                    <InputTextSingleLine
                      label="Employer stated that university preference is matched with employer objectives"
                      name="score_13b"
                      placeholder="0-10"
                      value={_formik.values.score_13b || ""}
                      onChange={_formik.handleChange}
                      onBlur={_formik.handleBlur}
                      invalid={
                        _formik.touched.score_13b && _formik.errors.score_13b
                          ? true
                          : false
                      }
                      errorMsg={_formik.errors.score_13b}
                    />
                    <InputTextSingleLine
                      label="Employer has solid future plan for applicants when return from study"
                      name="score_13c"
                      placeholder="0-10"
                      value={_formik.values.score_13c || ""}
                      onChange={_formik.handleChange}
                      onBlur={_formik.handleBlur}
                      invalid={
                        _formik.touched.score_13c && _formik.errors.score_13c
                          ? true
                          : false
                      }
                      errorMsg={_formik.errors.score_13c}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <div className="d-flex justify-content-end mb-3">
              <Link
                to="/pengajuan-beasiswa"
                className={`btn btn-primary btn-block me-2 ${
                  loading ? "disabled" : ""
                }`}
              >
                Back
              </Link>

              <button className="btn btn-primary btn-block" type="submit">
                {loading ? <Spinner size="sm" /> : "Submit"}
              </button>
            </div>
          </Form>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ScorePengajuanBeasiswa
