/* eslint-disable react/prop-types */
import React, { useState } from "react"
import { Input, FormFeedback, Label, Row } from "reactstrap"

const InputTextSingleLine = ({
  label,
  name,
  placeholder,
  value,
  invalid,
  errorMsg,
  infoMsg,
  onChange = () => {},
  onBlur = () => {},
}) => {
  return (
    <React.Fragment>
      <Row className="mb-2">
        <label htmlFor="example-text-input" className="col-md-10 col-form-label">
          {label}
        </label>
        <div className="col-md-2">
          <Input
            className="form-control"
            type="text"
            name={name}
            placeholder={placeholder}
            onChange={onChange}
            onBlur={onBlur}
            value={value}
            invalid={invalid}
          />
        </div>
      </Row>
      {infoMsg && <small className="form-text text-muted">{infoMsg}</small>}
      {invalid ? <FormFeedback type="invalid">{errorMsg}</FormFeedback> : null}
    </React.Fragment>
  )
}

export default InputTextSingleLine
