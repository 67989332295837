/* eslint-disable react/prop-types */

import React from "react"
import ReactEcharts from "echarts-for-react"

const BarChart = ({
  labelX = [],
  labelY = [],
  dataSeries = [120, 200, 150, 80, 70, 110, 130],
  typeX = "value",
  typeY = "value",
  color = "#3c4ccf",
}) => {
  const options = {
    grid: {
      left: "3%",
      right: "4%",
      bottom: "3%",
      containLabel: true,
    },
    // tooltip: {
    //   trigger: "axis",
    //   axisPointer: {
    //     type: "cross",
    //     crossStyle: {
    //       color: "#999",
    //     },
    //   },
    // },
    color: [color],
    xAxis: [
      {
        type: typeX,
        data: labelX,
        axisPointer: {
          type: "shadow",
        },
      },
    ],
    yAxis: [
      {
        type: typeY,
        data: labelY,
        axisPointer: {
          type: "shadow",
        },
      },
    ],
    series: [
      {
        data: dataSeries,
        type: "bar",
      },
    ],
    textStyle: {
      color: ["#74788d"],
    },
  }
  return (
    <React.Fragment>
      <ReactEcharts style={{ height: "350px" }} option={options} />
    </React.Fragment>
  )
}

export default BarChart
