import { del, get, post, put } from "../api_helper"
import * as url from "../url_helper"

const postAPIBeasiswaRegister = (data, page) => {
  var formData = new FormData()
  for (const key in data) {
    formData.append(key, data[key])
  }

  return post(url.POST_BEASISWA_REGISTER + page, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  })
}

const getAPIBeasiswaRegister = id => {
  return get(url.GET_BEASISWA_REGISTER + "/" + id, {
    headers: { "Content-Type": "multipart/form-data" },
  })
}

const getAPIDashboard = id => {
  return get(url.GET_DASHBOARD, {
    headers: { "Content-Type": "multipart/form-data" },
  })
}

const postAPIScoreBeasiswaRegister = data => {
  var formData = new FormData()
  for (const key in data) {
    formData.append(key, data[key])
  }

  return post(url.POST_SCORE_BEASISWA_REGISTER, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  })
}

const getAPIScoreBeasiswaRegister = id => {
  return get(url.GET_SCORE_BEASISWA_REGISTER + "/" + id, {
    headers: { "Content-Type": "multipart/form-data" },
  })
}

const updateApiStatusBeasiswaRegister = data => {
  var formData = new FormData()
  formData.append("id_register", data.id)
  formData.append("status", data.status_update)

  return post(url.POST_STATUS_BEASISWA_REGISTER, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  })
}

export {
  postAPIBeasiswaRegister,
  getAPIBeasiswaRegister,
  getAPIDashboard,
  postAPIScoreBeasiswaRegister,
  getAPIScoreBeasiswaRegister,
  updateApiStatusBeasiswaRegister,
}
