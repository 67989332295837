/* eslint-disable react/prop-types */

import React, { useState } from "react"
import { Alert, Col, Form, Row, Spinner } from "reactstrap"
import DivText from "components/Input/DivText"

const TabFormReferenceSummary = ({
  onPrev = () => {},
  onNext = () => {},
  onSuccess = () => {},
  lastPage = false,
  dataDetail = null,
}) => {
  return (
    <React.Fragment>
      <Form>
        {/* a. Name of Referee */}
        <div className="mb-3">
          <DivText
            label="Name of Referee"
            content={dataDetail?.r14_name_a || "-"}
          />
        </div>

        <Row>
          <Col md={6}>
            {/* Institution */}
            <div className="mb-3">
              <DivText
                label="Institution"
                content={dataDetail?.r14_institution_a || "-"}
              />
            </div>

            {/* Telephone*/}
            <div className="mb-3">
              <DivText label="Telephone" content={dataDetail?.r14_phone_a || "-"} />
            </div>
          </Col>
          <Col md={6}>
            {/* Position */}
            <div className="mb-3">
              <DivText
                label="Position"
                content={dataDetail?.r14_position_a || "-"}
              />
            </div>

            {/* Email */}
            <div className="mb-3">
              <DivText label="Email" content={dataDetail?.r14_email_a || "-"} />
            </div>
          </Col>
        </Row>

        <hr />

        {/* b. Name of Referee */}
        <div className="mb-3">
          <DivText
            label="Name of Referee"
            content={dataDetail?.r14_name_b || "-"}
          />
        </div>

        <Row>
          <Col md={6}>
            {/* Institution */}
            <div className="mb-3">
              <DivText
                label="Institution"
                content={dataDetail?.r14_institution_b || "-"}
              />
            </div>

            {/* Telephone*/}
            <div className="mb-3">
              <DivText label="Telephone" content={dataDetail?.r14_phone_b || "-"} />
            </div>
          </Col>
          <Col md={6}>
            {/* Position */}
            <div className="mb-3">
              <DivText
                label="Position"
                content={dataDetail?.r14_position_b || "-"}
              />
            </div>

            {/* Email */}
            <div className="mb-3">
              <DivText label="Email" content={dataDetail?.r14_email_b || "-"} />
            </div>
          </Col>
        </Row>

        <hr />

        {/* c. Name of Referee */}
        <div className="mb-3">
          <DivText
            label="Name of Referee"
            content={dataDetail?.r14_name_c || "-"}
          />
        </div>

        <Row>
          <Col md={6}>
            {/* Institution */}
            <div className="mb-3">
              <DivText
                label="Institution"
                content={dataDetail?.r14_institution_c || "-"}
              />
            </div>

            {/* Telephone*/}
            <div className="mb-3">
              <DivText label="Telephone" content={dataDetail?.r14_phone_c || "-"} />
            </div>
          </Col>
          <Col md={6}>
            {/* Position */}
            <div className="mb-3">
              <DivText
                label="Position"
                content={dataDetail?.r14_position_c || "-"}
              />
            </div>

            {/* Email */}
            <div className="mb-3">
              <DivText label="Email" content={dataDetail?.r14_email_c || "-"} />
            </div>
          </Col>
        </Row>

        <hr />
      </Form>
    </React.Fragment>
  )
}

export default TabFormReferenceSummary
