/* eslint-disable react/display-name */
import React, { useEffect, useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Button,
  Spinner,
} from "reactstrap"
import MetaTags from "react-meta-tags"

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import "../../assets/scss/datatables.scss"
import { getApiUser } from "helpers/BackendHelper/kelola-user-backend"
import FormAdmin from "./form-admin"
import DeleteAdmin from "./delete-admin"
import { getApiAdmin } from "helpers/BackendHelper/kelola-admin-backend"

const KelolaAdminPage = () => {
  const [limit, setLimit] = useState(10)
  const [offset, setOffset] = useState()
  const [isFormOpen, setFormOpen] = useState(false)
  const [isDeleteOpen, setDeleteOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [dataAdmin, setDataAdmin] = useState(null)

  useEffect(() => {
    handleTableChange("page", { page: 1, sizePerPage: limit })
  }, [])
  const columns = [
    {
      dataField: "name",
      text: "Name",
      sort: true,
    },
    {
      dataField: "email",
      text: "Email",
      sort: true,
    },
    {
      dataField: "id",
      text: "Action",
      sort: false,
      formatter: (cell, row) => {
        return (
          <div>
            <Button
              color="warning"
              className="btn-rounded btn-sm me-1"
              onClick={() => showEditForm(row)}
            >
              <i className="bx bx-pencil"></i>
            </Button>
            <Button
              color="danger"
              className="btn-rounded btn-sm"
              onClick={() => showDeleteForm(row)}
            >
              <i className="bx bx-trash"></i>
            </Button>
          </div>
        )
      },
    },
  ]

  const [productData, setProductData] = useState([])
  const [pageOptions, setPageOptions] = useState({
    page: 1,
    sizePerPage: limit,
    totalSize: 0,
    custom: true,
  })
  const handleTableChange = async (
    type,
    { page, sizePerPage, searchText, sortField, sortOrder }
  ) => {
    const _offset = (page - 1) * limit

    let sort = ""
    if (!searchText) searchText = ""

    if (sortField) sort = `&order_name=${sortField}&order_dir=${sortOrder}`

    try {
      setLoading(true)
      const response = await getApiAdmin(_offset, limit, searchText, sort)
      setLoading(false)
      if (response.status === "SUCCESS") {
        setOffset((page - 1) * limit)
        setProductData(response.data)
        setPageOptions({
          ...pageOptions,
          page: page,
          totalSize: response.data_total,
        })
      } else {
      }
    } catch (error) {}
  }

  const onRefreshTable = async () => {
    try {
      setLoading(true)
      const response = await getApiAdmin(offset, limit)
      setLoading(false)
      if (response.status === "SUCCESS") {
        setProductData(response.data)
      } else {
      }
    } catch (error) {}
  }

  const showEditForm = data => {
    setDataAdmin(data)
    setFormOpen(true)
  }

  const showDeleteForm = data => {
    setDataAdmin(data)
    setDeleteOpen(true)
  }

  const { SearchBar } = Search

  return (
    <React.Fragment>
      <FormAdmin
        dataAdmin={dataAdmin}
        isOpen={isFormOpen}
        onClose={() => {
          setFormOpen(false)
        }}
        onRefreshTable={() => onRefreshTable()}
      />
      <DeleteAdmin
        dataAdmin={dataAdmin}
        isOpen={isDeleteOpen}
        onClose={() => {
          setDeleteOpen(false)
        }}
        onRefreshTable={() => onRefreshTable()}
      />
      <div className="page-content">
        <MetaTags>
          <title>Kelola Admin | CMS - Beasiswa BUMN</title>
        </MetaTags>
        <div className="container-fluid">
          <Breadcrumbs
            title="Kelola Admin"
            breadcrumbItem=""
            titleMenu="Kelola Admin"
          />

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField="id"
                    columns={columns}
                    data={productData}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        bootstrap4
                        keyField="id"
                        columns={columns}
                        data={productData}
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <Row className="mb-2">
                              <Col md="10">
                                <div className="search-box me-2 mb-2 d-inline-block">
                                  <div className="position-relative">
                                    <SearchBar {...toolkitProps.searchProps} />
                                    <i className="bx bx-search-alt search-icon" />
                                  </div>
                                </div>
                              </Col>
                              <Col md="2" style={{textAlign: 'end'}}>
                                <Button
                                  color="primary"
                                  className="btn btn-primary btn-block"
                                  type="submit"
                                  onClick={() => {
                                    setDataAdmin(null)
                                    setFormOpen(true)
                                  }}
                                >
                                  Add Admin
                                </Button>
                              </Col>
                            </Row>

                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    remote={{ search: true, sort: true, pagination: true }}
                                    keyField={"id"}
                                    responsive
                                    bordered={false}
                                    striped={true}
                                    classes={"table align-middle table-nowrap"}
                                    headerWrapperClasses={"thead-light"}
                                    loading={true} //only loading is true, react-bootstrap-table will render overlay
                                    //overlay={ overlayFactory() }
                                    onTableChange={handleTableChange}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                  />
                                </div>
                              </Col>
                            </Row>

                            <Row className="align-items-md-center mt-30">
                              <Col className="inner-custom-pagination d-flex">
                                <div className="text-md-right ms-auto">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                  {loading && (
                    <div>
                      <Spinner size={"sm"} className="me-2" />{" "}
                      <span>Please wait, Get Data...</span>
                    </div>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default KelolaAdminPage
