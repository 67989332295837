import { del, get, post, put } from "../api_helper"
import * as url from "../url_helper"

const postApiAddAdmin = (data) => {

  var formData = new FormData()
  formData.append("email", data.email)
  formData.append("name", data.name)
  formData.append("password", data.password)

  return post(url.GET_ADMIN + `/add`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  })
}

const getApiAdmin = (offset, limit, search, sort) => {

  return get(url.GET_ADMIN + `?offset=${offset}&limit=${limit}&search=${search}` + sort, {
    headers: { "Content-Type": "multipart/form-data" },
  })
}

const deleteApiAdmin = (id) => {

  return del(url.GET_ADMIN + `/${id}`, {
    headers: { "Content-Type": "multipart/form-data" },
  })
}

const postApiUpdateAdmin = data => {
  var formData = new FormData()
  formData.append("name", data.name)
  formData.append("id_admin", data.id_admin)
  
  return post(url.POST_UPDATE_PROFILE, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  })
}


export { postApiAddAdmin, getApiAdmin, deleteApiAdmin, postApiUpdateAdmin  }
