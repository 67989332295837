/* eslint-disable react/display-name */
import React, { useEffect, useState } from "react"
import { Row, Col, Card, CardBody, CardTitle, Button, Spinner } from "reactstrap"
import MetaTags from "react-meta-tags"

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import "../../assets/scss/datatables.scss"
import { getApiUser } from "helpers/BackendHelper/kelola-user-backend"

const KelolaUserPage = () => {
  const [limit, setLimit] = useState(10)
  const [offset, setOffset] = useState()
  const [isFormOpen, setFormOpen] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    handleTableChange("page", { page: 1, sizePerPage: limit })
  }, [])
  const columns = [
    {
      dataField: "photo_url",
      text: "",
      sort: false,
      formatter: row => {
        return (
          <img
            className="rounded-circle header-profile-user cover-full"
            src={row}
            alt="avatar"
          />
        )
      },
    },
    {
      dataField: "name",
      text: "Name",
      sort: true,
    },
    {
      dataField: "email",
      text: "Email",
      sort: true,
    },

    {
      dataField: "register_time",
      text: "Register Time",
      sort: true,
    },
  ]

  const [productData, setProductData] = useState([])
  const [pageOptions, setPageOptions] = useState({
    page: 1,
    sizePerPage: limit,
    totalSize: 0,
    custom: true,
  })

  const handleTableChange = async (type, { page, sizePerPage, searchText, sortField, sortOrder }) => {
    const _offset = (page - 1) * limit

    let sort = "";
    if(!searchText)
      searchText = "";

    if(sortField)
      sort = `&order_name=${sortField}&order_dir=${sortOrder}`;

    try {
      setLoading(true)
      const response = await getApiUser(_offset, limit, searchText, sort)
      setLoading(false)
      if (response.status === "SUCCESS") {
        setOffset((page - 1) * limit)
        setProductData(response.data)
        setPageOptions({
          ...pageOptions,
          page: page,
          totalSize: response.data_total,
        })
      } else {
      }
    } catch (error) {}
  }

  const { SearchBar } = Search;

  return (
    <React.Fragment>
    
      <div className="page-content">
        <MetaTags>
          <title>Kelola User | CMS - Beasiswa BUMN</title>
        </MetaTags>
        <div className="container-fluid">
          <Breadcrumbs
            title="Kelola User"
            breadcrumbItem=""
            titleMenu="Kelola User"
          />

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField="id"
                    columns={columns}
                    data={productData}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                      bootstrap4
                      keyField="id"
                      columns={columns}
                      data={productData}
                      search
                      >
                        {toolkitProps => (
                          
                          <React.Fragment>
                            <Row className="mb-2">
                              <Col md="4">
                                <div className="search-box me-2 mb-2 d-inline-block">
                                  <div className="position-relative">
                                    <SearchBar {...toolkitProps.searchProps} />
                                    <i className="bx bx-search-alt search-icon" />
                                  </div>
                                </div>
                              </Col>
                            </Row>

                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    remote={ { search: true, sort: true, pagination: true } }
                                    keyField={"id"}
                                    responsive
                                    bordered={false}
                                    striped={true}
                                    classes={"table align-middle table-nowrap"}
                                    headerWrapperClasses={"thead-light"}
                                    loading={true} //only loading is true, react-bootstrap-table will render overlay
                                    onTableChange={handleTableChange}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                  />
                                </div>
                              </Col>
                            </Row>

                            <Row className="align-items-md-center mt-30">
                              <Col className="inner-custom-pagination d-flex">
                                <div className="text-md-right ms-auto">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>

                  {loading && (
                    <div>
                      <Spinner size={"sm"} className="me-2" />{" "}
                      <span>Please wait, Get Data...</span>
                    </div>
                  )}

                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default KelolaUserPage
