import axios from "axios"
import { del, get, post, put } from "./api_helper"
import * as url from "./url_helper"

// Gets the logged in user data from local session
const getLoggedInUser = () => {
  const user = localStorage.getItem("user")
  if (user) return JSON.parse(user)
  return null
}

//is user is logged in
const isUserAuthenticated = () => {
  return getLoggedInUser() !== null
}

// Register Method
const postApiRegister = data => {
  var formData = new FormData()
  formData.append("email", data.email)
  formData.append("password", data.password)
  formData.append("name", data.name)

  return post(url.POST_REGISTER, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  })
}

// Login Method
const postApiLogin = async data => {
  var formData = new FormData()
  formData.append("email", data.email)
  formData.append("password", data.password)

  let response = await post(url.POST_LOGIN, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  })

  try {
    if (response.status === "SUCCESS") {
      const responseProfile = await get(url.GET_USER, {
        headers: { token: response.token },
      })
      if (responseProfile.status === "SUCCESS") {
        let resp = {
          ...response,
          email: responseProfile.data[0].email,
          photo_url: responseProfile.data[0].photo_url,
        }

        return resp;
      } else {
        return responseProfile;
      }
    } else {
      return response;
    }
  } catch (e) {
    return response;
  }
}

// postForgetPwd
const postApiResetPassword = data => {
  var formData = new FormData()
  formData.append("email", data.email)

  return post(url.POST_FORGET_PASSWORD, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  })
}

// postValidasiEmail
const postApiValidasiEmail = code => {
  var formData = new FormData()
  formData.append("code", code)

  return post(url.POST_VALIDASI_EMAIL, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  })
}

export {
  getLoggedInUser,
  isUserAuthenticated,
  postApiRegister,
  postApiLogin,
  postApiResetPassword,
  postApiValidasiEmail,
}
