import {
  FORGET_PASSWORD,
  FORGET_PASSWORD_SUCCESS,
  FORGET_PASSWORD_ERROR,
} from "./actionTypes"

export const userForgetPassword = (data) => {
  return {
    type: FORGET_PASSWORD,
    payload: { data },
  }
}

export const userForgetPasswordSuccess = response => {
  return {
    type: FORGET_PASSWORD_SUCCESS,
    payload: response,
  }
}

export const userForgetPasswordError = response => {
  return {
    type: FORGET_PASSWORD_ERROR,
    payload: response,
  }
}
