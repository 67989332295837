/* eslint-disable react/prop-types */
import React, { useState } from "react"
import { Input, FormFeedback, Label } from "reactstrap"

const DivText = ({ label, content, mb = "24px" }) => {
  return (
    <React.Fragment>
      {label && <Label className="form-label">{label}</Label>}

      <p className="card-title-desc" style={{marginBottom: mb}}>{content}</p>
    </React.Fragment>
  )
}

export default DivText
