import axios from "axios"
import accessToken from "./jwt-token-access/accessToken"

//pass new generated access token here
const obj = JSON.parse(localStorage.getItem("authUser"))
const token = obj && obj.token ? obj.token : {};

//apply base url for axios
//const API_URL = "https://beasiswabumn.id/api"
const API_URL = "https://beasiswabumn.jagoan.info/api" 


const axiosApi = axios.create({
  baseURL: API_URL,
})

axiosApi.defaults.headers.common["token"] = token;
//axiosApi.defaults.headers.common["Content-Type"] = "multipart/form-data";


axiosApi.interceptors.response.use(
  response => response,
  error => Promise.reject(error)
)

export function setToken(){
  const obj = JSON.parse(localStorage.getItem("authUser"))
  const token = obj && obj.token ? obj.token : {};
  axiosApi.defaults.headers.common["token"] = token;
}


export async function get(url, config = {}) {
  return await axiosApi.get(url, { ...config }).then(response => response.data)
}

export async function post(url, data, config = {}) {
  return axiosApi
    .post(url, data, { ...config })
    .then(response => {
      return response.data
    })
}

export async function put(url, data, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function del(url, config = {}) {
  return await axiosApi
    .delete(url, { ...config })
    .then(response => response.data)
}
