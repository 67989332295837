/* eslint-disable react/prop-types */

import React, { useState } from "react"
import { Form } from "reactstrap"
import DivText from "components/Input/DivText"

const TabFormPhysicalDisabilities = ({
  onPrev = () => {},
  onNext = () => {},
  onSuccess = () => {},
  lastPage = false,
  dataDetail = null,
}) => {
  return (
    <React.Fragment>
      <Form>
        {/* PHYSICAL DISABILITY */}
        <div className="mb-3">
          <DivText
            label="Physical Disability"
            content={dataDetail?.r02_disability || "-"}
          />
        </div>

        <hr />
      </Form>
    </React.Fragment>
  )
}

export default TabFormPhysicalDisabilities
