import React, { useEffect } from "react"
import MetaTags from "react-meta-tags"
import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Input,
  Label,
  Form,
  FormFeedback,
} from "reactstrap"

// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"

// action
import { registerUser, apiError } from "../../store/actions"

//redux
import { useSelector, useDispatch } from "react-redux"

import { Link } from "react-router-dom"

// import images
import profileImg from "assets/images/logo-dark.png"
import logoImg from "../../assets/images/logo.svg"

const Register = props => {
  const dispatch = useDispatch()

  const _formik = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: "",
      name: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
      name: Yup.string().required("Please Enter Your Name"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: values => {
      dispatch(registerUser(values))
    },
  })

  const { user, registrationError, registrationSuccess, loading } = useSelector(
    state => ({
      user: state.Account.user,
      registrationError: state.Account.registrationError,
      registrationSuccess: state.Account.registrationSuccess,
      loading: state.Account.loading,
    })
  )

  // handleValidSubmit
  const handleValidSubmit = values => {
    dispatch(registerUser(values))
  }

  useEffect(() => {
    dispatch(apiError(""))
  }, [])

  return (
    <React.Fragment>
      <MetaTags>
        <title>Register | Beasiswa BUMN</title>
      </MetaTags>
      <div className="auth-full-bg">
        <div className="account-pages bg-overlay">
          <Container>
            <Row className="justify-content-center mt-5">
              <Col md={8} lg={6} xl={5}>
                <Card className="overflow-hidden card-login">
                  <div className="bg-primary bg-soft  p-2">
                    <Row>
                      <Col className="col-7">
                        <div className="text-primary p-4">
                          <h5 className="text-primary">Register</h5>
                        </div>
                      </Col>
                      <Col className="col-5 align-self-end">
                        <img src={profileImg} alt="" className="img-fluid" />
                      </Col>
                    </Row>
                  </div>
                  <CardBody className="pt-0">
                    <div className="p-2 mt-4">
                      <Form
                        className="form-horizontal"
                        onSubmit={e => {
                          e.preventDefault()
                          _formik.handleSubmit()
                          return false
                        }}
                      >
                        {registrationSuccess && registrationSuccess ? (
                          <Alert color="success">
                            Pendaftaran Berhasil, email konfirmasi telah dikirim
                            ke: {_formik.values.email}
                          </Alert>
                        ) : (
                          <React.Fragment>
                            {registrationError && registrationError ? (
                              <Alert color="danger">
                                Error: {registrationError.message}
                              </Alert>
                            ) : null}

                            <div className="mb-3">
                              <Label className="form-label">Email</Label>
                              <Input
                                id="email"
                                name="email"
                                className="form-control"
                                placeholder="Enter email"
                                type="email"
                                onChange={_formik.handleChange}
                                onBlur={_formik.handleBlur}
                                value={_formik.values.email || ""}
                                invalid={
                                  _formik.touched.email && _formik.errors.email
                                    ? true
                                    : false
                                }
                              />
                              {_formik.touched.email && _formik.errors.email ? (
                                <FormFeedback type="invalid">
                                  {_formik.errors.email}
                                </FormFeedback>
                              ) : null}
                            </div>

                            <div className="mb-3">
                              <Label className="form-label">Password</Label>
                              <Input
                                name="password"
                                type="password"
                                placeholder="Enter Password"
                                onChange={_formik.handleChange}
                                onBlur={_formik.handleBlur}
                                value={_formik.values.password || ""}
                                invalid={
                                  _formik.touched.password &&
                                  _formik.errors.password
                                    ? true
                                    : false
                                }
                              />
                              {_formik.touched.password &&
                              _formik.errors.password ? (
                                <FormFeedback type="invalid">
                                  {_formik.errors.password}
                                </FormFeedback>
                              ) : null}
                            </div>

                            <div className="mb-3">
                              <Label className="form-label">Name</Label>
                              <Input
                                name="name"
                                type="text"
                                placeholder="Enter name"
                                onChange={_formik.handleChange}
                                onBlur={_formik.handleBlur}
                                value={_formik.values.name || ""}
                                invalid={
                                  _formik.touched.name && _formik.errors.name
                                    ? true
                                    : false
                                }
                              />
                              {_formik.touched.name && _formik.errors.name ? (
                                <FormFeedback type="invalid">
                                  {_formik.errors.name}
                                </FormFeedback>
                              ) : null}
                            </div>

                            <div className="mt-4 d-grid">
                              <button
                                className="btn btn-primary btn-block "
                                type="submit"
                              >
                                Register
                              </button>
                            </div>
                          </React.Fragment>
                        )}

                        <div className="mt-4 text-center">
                          <p>
                            {registrationSuccess
                              ? "Kembali ke halaman"
                              : "Already have an account ?"}
                            <Link
                              to="/login"
                              className="font-weight-medium text-primary"
                            >
                              {" "}
                              Login
                            </Link>{" "}
                          </p>
                        </div>
                      </Form>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Register
