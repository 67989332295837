import MetaTags from "react-meta-tags"
import React, { useState, useEffect } from "react"
import { Card, Alert, CardBody, Button, Form } from "reactstrap"

// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"

// actions
import InputText from "components/Input/InputText"
import { postApiUpdatePassword } from "helpers/BackendHelper/profile_backend"
import InputPassword from "components/Input/InputPassword"

const CompChangePassword = props => {
  const [error, setError] = useState("")
  const [success, setSuccess] = useState("")

  const _formik = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: false,

    initialValues: {
      password_current: "",
      password_new: "",
    },

    validationSchema: Yup.object({
      password_current: Yup.string().required("Please Enter Old Password"),
      password_new: Yup.string().required("Please Enter New Password"),
      password_new_confirm: Yup.string().oneOf(
        [Yup.ref("password_new"), null],
        "Your new passwords do not match."
      ),
    }),

    onSubmit: values => {
      updatePassword(values)
    },
  })

  const updatePassword = async data => {
    try {
      const response = await postApiUpdatePassword(data)
      if (response.status === "SUCCESS") {
        setSuccess("Password berhasil diupdate")
        setTimeout(() => {
          setSuccess("")
        }, 5000)
      } else {
        setError(response.message)
        setTimeout(() => {
          setError("")
        }, 5000)
      }
    } catch (error) {
      setError("Something went wrong, please try again")
      setTimeout(() => {
        setError("")
      }, 5000)
    }
  }

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          {error && error ? <Alert color="danger">Error: {error}</Alert> : null}

          {success && success ? (
            <Alert color="success">Success: {success}</Alert>
          ) : null}
          <Form
            className="form-horizontal"
            onSubmit={e => {
              e.preventDefault()
              _formik.handleSubmit()
              return false
            }}
          >
            <div className="form-group">
              {/* PASSWORD LAMA */}
              <div className="mb-3">
                <InputPassword
                  label="Password Lama"
                  name="password_current"
                  placeholder=""
                  value={_formik.values.password_current || ""}
                  onChange={_formik.handleChange}
                  onBlur={_formik.handleBlur}
                  invalid={
                    _formik.touched.password_current &&
                    _formik.errors.password_current
                      ? true
                      : false
                  }
                  errorMsg={_formik.errors.password_current}
                />
              </div>

              {/* PASSWORD LAMA */}
              <div className="mb-3">
                <InputPassword
                  label="Password Baru"
                  name="password_new"
                  placeholder=""
                  value={_formik.values.password_new || ""}
                  onChange={_formik.handleChange}
                  onBlur={_formik.handleBlur}
                  invalid={
                    _formik.touched.password_new && _formik.errors.password_new
                      ? true
                      : false
                  }
                  errorMsg={_formik.errors.password_new}
                />
              </div>

              {/* KONDIFIRMASI PASSWORD BARU */}
              <div className="mb-3">
                <InputPassword
                  label="Konsifrmasi Password Baru"
                  name="password_new_confirm"
                  placeholder=""
                  value={_formik.values.password_new_confirm || ""}
                  onChange={_formik.handleChange}
                  onBlur={_formik.handleBlur}
                  invalid={
                    _formik.touched.password_new_confirm && _formik.errors.password_new_confirm
                      ? true
                      : false
                  }
                  errorMsg={_formik.errors.password_new_confirm}
                />
              </div>
            </div>
            <div className="text-center mt-4">
              <Button type="submit" color="danger">
                Update Password
              </Button>
            </div>
          </Form>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default CompChangePassword
