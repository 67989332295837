import { takeEvery, fork, put, all, call } from "redux-saga/effects"

import { EDIT_PROFILE } from "./actionTypes"
import { profileSuccess, profileError } from "./actions"
import { postApiUpdateProfile } from "helpers/BackendHelper/profile_backend"

function* editProfile({ payload: { user } }) {
  try {
    const response = yield call(postApiUpdateProfile, user)
    if(response.status === "SUCCESS"){
      yield put(profileSuccess(response))
    }
    else{
      yield put(profileError(response))
    }  
  } catch (error) {
    yield put(profileError(error))
  }
}

export function* watchProfile() {
  yield takeEvery(EDIT_PROFILE, editProfile)
}

function* ProfileSaga() {
  yield all([fork(watchProfile)])
}

export default ProfileSaga
