import MetaTags from "react-meta-tags"
import React, { useState, useEffect } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form,
} from "reactstrap"

// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"

//redux
import { useSelector, useDispatch } from "react-redux"

import { withRouter } from "react-router-dom"

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb"

import avatar from "../../assets/images/users/avatar-1.jpg"
// actions
import { editProfile, resetProfileFlag } from "../../store/actions"
import InputText from "components/Input/InputText"
import InputFile from "components/Input/InputFile"

//Components
import CompChangePassword from "./components/change-password"
import CompChangeEmail from "./components/change-email"
import InputRadio from "components/Input/InputRadio"
import CompChangeProfile from "./components/change-profile"

const UserProfile = props => {
  const dispatch = useDispatch()

  const [email, setemail] = useState("")

  const { error, success } = useSelector(state => ({
    error: state.Profile.error,
    success: state.Profile.success,
  }))

  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      const obj = JSON.parse(localStorage.getItem("authUser"))
      setemail(obj.email);

      setTimeout(() => {
        dispatch(resetProfileFlag())
      }, 3000)
    }
  }, [dispatch, success])

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Profil | CMS - Beasiswa BUMN</title>
        </MetaTags>
        <Container fluid>
           {/* Render Breadcrumb */}
           <Breadcrumb title="Profile" breadcrumbItem="" titleMenu="Profile" />

           {/* CHANGE PROFILE */}
           <CompChangeProfile />

          {/* CHANGE PASSWORD */}
          <CompChangePassword />

          {/* CHANGE EMAIL */}
          <CompChangeEmail email={email}/>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(UserProfile)
