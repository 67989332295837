/* eslint-disable react/prop-types */

import React, { useState } from "react"
import { Alert, Col, Form, Row, Spinner } from "reactstrap"

import DivText from "components/Input/DivText"

const TabFormPublications = ({
  onPrev = () => {},
  onNext = () => {},
  onSuccess = () => {},
  lastPage = false,
  dataDetail = null,
}) => {
  return (
    <React.Fragment>
      <Form>
        {/* Title of Article */}
        <div className="mb-3">
          <DivText
            label="Title of Article"
            content={dataDetail?.r09_publication_title_a || "-"}
          />
        </div>

        <Row>
          <Col md={6}>
            {/* Publication Date */}
            <div className="mb-3">
              <DivText
                label="Publication Date"
                content={dataDetail?.r09_publication_date_a || "-"}
              />
            </div>
          </Col>
          <Col md={6}>
            {/* Name of Publication */}
            <div className="mb-3">
              <DivText
                label="Name of Publication"
                content={dataDetail?.r09_publication_name_a || "-"}
              />
            </div>
          </Col>
        </Row>

        {/* Co-author(s) */}
        <div className="mb-3">
          <DivText
            label="Co-author(s)"
            content={dataDetail?.r09_co_author_a || "-"}
          />
        </div>

        <hr />

        {/* Title of Article */}
        <div className="mb-3">
          <DivText
            label="Title of Article"
            content={dataDetail?.r09_publication_title_b || "-"}
          />
        </div>

        <Row>
          <Col md={6}>
            {/* Publication Date */}
            <div className="mb-3">
              <DivText
                label="Publication Date"
                content={dataDetail?.r09_publication_date_b || "-"}
              />
            </div>
          </Col>
          <Col md={6}>
            {/* Name of Publication */}
            <div className="mb-3">
              <DivText
                label="Name of Publication"
                content={dataDetail?.r09_publication_name_b || "-"}
              />
            </div>
          </Col>
        </Row>

        {/* Co-author(s) */}
        <div className="mb-3">
          <DivText
            label="Co-author(s)"
            content={dataDetail?.r09_co_author_b || "-"}
          />
        </div>

        <hr />
      </Form>
    </React.Fragment>
  )
}

export default TabFormPublications
