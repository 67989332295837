/* eslint-disable react/prop-types */

import React, { useState } from "react"
import { Col, Form, Row } from "reactstrap"

import DivText from "components/Input/DivText"

const TabFormPersonalInformation = ({
  onPrev = () => {},
  onNext = () => {},
  onSuccess = () => {},
  lastPage = false,
  dataDetail = null,
}) => {
  const [error, setError] = useState("")

  return (
    <React.Fragment>
      <Form>
        {/* FIRST NAME */}
        <div className="mb-3">
          <DivText
            label="First Name"
            content={dataDetail?.r01_first_name || "-"}
          />
        </div>

        {/* Middle NAME */}
        <div className="mb-3">
          <DivText
            label="Middle Name"
            content={dataDetail?.r01_middle_name || "-"}
          />
        </div>

        {/* LAST NAME */}
        <div className="mb-3">
          <DivText
            label="Last Name"
            content={dataDetail?.r01_last_name || "-"}
          />
        </div>
        <hr />
        <Row>
          <Col md={6}>
            {/* Nationality */}
            <div className="mb-3">
              <DivText
                label="Nationality"
                content={dataDetail?.r01_nationality || "-"}
              />
            </div>

            {/* NATIONAL ID DETAILS */}
            <div className="mb-3">
              <DivText
                label="National ID Details"
                content={dataDetail?.r01_id_type || "-"}
              />
            </div>

            {/* NUMBER NATIONAL ID */}
            <div className="mb-3">
              <DivText
                label="National ID Number"
                content={dataDetail?.r01_id_number || "-"}
              />
            </div>

            {/* EXPIRY DATE */}
            <div className="mb-3">
              <DivText
                label="National ID Expiry Date (Passport)"
                content={dataDetail?.r01_id_expiry || "-"}
              />
            </div>
          </Col>
          <Col md={6}>
            {/* DATE OF BIRTH */}
            <div className="mb-3">
              <DivText
                label="Date of Birth"
                content={dataDetail?.r01_birth_date || "-"}
              />
            </div>

            {/* Place of Birth */}
            <div className="mb-3">
              <DivText
                label="Place of Birth"
                content={dataDetail?.r01_birth_place || "-"}
              />
            </div>

            {/* GENDER */}
            <div className="mb-3">
              <DivText label="Gender" content={dataDetail?.r01_gender || "-"} />
            </div>
          </Col>
        </Row>

        <hr />

        <Row>
          <Col md={6}>
            {/* City */}
            <div className="mb-3">
              <DivText label="City" content={dataDetail?.r01_city || "-"} />
            </div>

            {/* Province */}
            <div className="mb-3">
              <DivText
                label="Province"
                content={dataDetail?.r01_province || "-"}
              />
            </div>

            {/* POSTAL CODE */}
            <div className="mb-3">
              <DivText
                label="Postal Code"
                content={dataDetail?.r01_postal_code || "-"}
              />
            </div>
          </Col>
          <Col md={6}>
            {/* CURRENT RESIDENTIAL ADDRESS */}
            <div className="mb-3">
              <DivText
                label="Current Residential Address"
                content={dataDetail?.r01_address || "-"}
              />
            </div>
          </Col>
        </Row>

        <hr />

        <Row>
          <Col md={6}>
            {/* HOME TELEPHONE */}
            <div className="mb-3">
              <DivText
                label="Home Telephone"
                content={dataDetail?.r01_home_phone || "-"}
              />
            </div>

            {/* Email */}
            <div className="mb-3">
              <DivText label="Email" content={dataDetail?.r01_email || "-"} />
            </div>
          </Col>
          <Col md={6}>
            {/* MOBILE TELEPHONE */}
            <div className="mb-3">
              <DivText
                label="Mobile Number"
                content={dataDetail?.r01_mobile_phone || "-"}
              />
            </div>

            {/* Marital Status */}
            <div className="mb-3">
              <DivText
                label="Marital Status"
                content={dataDetail?.r01_marital_status || "-"}
              />
            </div>
          </Col>
        </Row>

        <hr />
      </Form>
    </React.Fragment>
  )
}

export default TabFormPersonalInformation
