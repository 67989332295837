/* eslint-disable react/prop-types */

import InputText from "components/Input/InputText"
import { useFormik } from "formik"
import * as Yup from "yup"
import React, { useState } from "react"
import { Alert, Col, Form, Row, Spinner } from "reactstrap"
import InputNumber from "components/Input/InputNumber"
import InputDateRangeMask from "components/Input/InputDateRangeMask"

import { Link } from "react-router-dom"
import { postAPIBeasiswaRegister } from "helpers/BackendHelper/beasiswa_register_backend"
import { scrollTop, swapFormatedDate } from "helpers/utils_helper"
import DivText from "components/Input/DivText"

const TabFormEducationalBackground = ({
  onPrev = () => {},
  onNext = () => {},
  onSuccess = () => {},
  lastPage = false,
  dataDetail = null,
}) => {
  return (
    <React.Fragment>
      <Form
        onSubmit={e => {
          e.preventDefault()
          _formik.handleSubmit()
          return false
        }}
      >
        <Row>
          <Col md={6}>
            {/* A. NAME OF UNIVERSITY */}
            <div className="mb-3">
              <DivText
                label="Name of University"
                content={dataDetail?.r05_university_a || "-"}
              />
            </div>

            {/* LOCATION, CITY */}
            <div className="mb-3">
              <DivText
                label="Location, City"
                content={dataDetail?.r05_city_a || "-"}
              />
            </div>

            {/* DATE ATTENDED */}
            <div className="mb-3">
              <DivText
                label="Date Attended"
                content={dataDetail?.r05_date_a || "-"}
              />
            </div>

            {/* FACULTY */}
            <div className="mb-3">
              <DivText label="Faculty" content={dataDetail?.r05_faculty_a || "-"} />
            </div>
          </Col>
          <Col md={6}>
            {/* DEGREE OBTAINED */}
            <div className="mb-3">
              <DivText label="Faculty" content={dataDetail?.r05_faculty_a || "-"} />
            </div>

            {/* COUNTRY */}
            <div className="mb-3">
              <DivText label="Country" content={dataDetail?.r05_country_a || "-"} />
            </div>

            {/* CURRENT OR FINAL GPA */}
            <div className="mb-3">
              <DivText
                label="Current or Final GPA"
                content={dataDetail?.r05_gpa_a || "-"}
              />
            </div>

            {/* MAJOR */}
            <div className="mb-3">
              <DivText label="Major" content={dataDetail?.r05_major_a || "-"} />
            </div>
          </Col>
        </Row>

        <hr />

        <Row>
          <Col md={6}>
            {/* B. NAME OF UNIVERSITY */}
            <div className="mb-3">
              <DivText
                label="Name of University"
                content={dataDetail?.r05_university_b || "-"}
              />
            </div>

            {/* LOCATION, CITY */}
            <div className="mb-3">
              <DivText
                label="Location, City"
                content={dataDetail?.r05_city_b || "-"}
              />
            </div>

            {/* DATE ATTENDED */}
            <div className="mb-3">
              <DivText
                label="Date Attended"
                content={dataDetail?.r05_date_b || "-"}
              />
            </div>

            {/* FACULTY */}
            <div className="mb-3">
              <DivText label="Faculty" content={dataDetail?.r05_faculty_b || "-"} />
            </div>
          </Col>
          <Col md={6}>
            {/* DEGREE OBTAINED */}
            <div className="mb-3">
              <DivText label="Faculty" content={dataDetail?.r05_faculty_b || "-"} />
            </div>

            {/* COUNTRY */}
            <div className="mb-3">
              <DivText label="Country" content={dataDetail?.r05_country_b || "-"} />
            </div>

            {/* CURRENT OR FINAL GPA */}
            <div className="mb-3">
              <DivText
                label="Current or Final GPA"
                content={dataDetail?.r05_gpa_b || "-"}
              />
            </div>

            {/* MAJOR */}
            <div className="mb-3">
              <DivText label="Major" content={dataDetail?.r05_major_b || "-"} />
            </div>
          </Col>
        </Row>

        <hr />

        <Row>
          <Col md={6}>
            {/* C. NAME OF UNIVERSITY */}
            <div className="mb-3">
              <DivText
                label="Name of University"
                content={dataDetail?.r05_university_c || "-"}
              />
            </div>

            {/* LOCATION, CITY */}
            <div className="mb-3">
              <DivText
                label="Location, City"
                content={dataDetail?.r05_city_c || "-"}
              />
            </div>

            {/* DATE ATTENDED */}
            <div className="mb-3">
              <DivText
                label="Date Attended"
                content={dataDetail?.r05_date_c || "-"}
              />
            </div>

            {/* FACULTY */}
            <div className="mb-3">
              <DivText label="Faculty" content={dataDetail?.r05_faculty_c || "-"} />
            </div>
          </Col>
          <Col md={6}>
            {/* DEGREE OBTAINED */}
            <div className="mb-3">
              <DivText label="Faculty" content={dataDetail?.r05_faculty_c || "-"} />
            </div>

            {/* COUNTRY */}
            <div className="mb-3">
              <DivText label="Country" content={dataDetail?.r05_country_c || "-"} />
            </div>

            {/* CURRENT OR FINAL GPA */}
            <div className="mb-3">
              <DivText
                label="Current or Final GPA"
                content={dataDetail?.r05_gpa_c || "-"}
              />
            </div>

            {/* MAJOR */}
            <div className="mb-3">
              <DivText label="Major" content={dataDetail?.r05_major_c || "-"} />
            </div>
          </Col>
        </Row>
      </Form>
    </React.Fragment>
  )
}

export default TabFormEducationalBackground
