
import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import {
  Container, Row,
} from "reactstrap"

class StatusPengajuanBeasiswa extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>CMS | Beasiswa BUMN</title>
          </MetaTags>
          <Container fluid>
          <Row>
           <h1>Status Pengajuan Beasiswa</h1>
          </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

export default StatusPengajuanBeasiswa;
