/* eslint-disable react/prop-types */
import InputEmail from "components/Input/InputEmail"
import InputPassword from "components/Input/InputPassword"
import InputText from "components/Input/InputText"
import { useFormik } from "formik"
import React, { useState } from "react"
import MetaTags from "react-meta-tags"
import { Link } from "react-router-dom"
import * as Yup from "yup"
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Modal,
  Container,
  Form,
  Alert,
  Spinner,
} from "reactstrap"
import { deleteApiAdmin } from "helpers/BackendHelper/kelola-admin-backend"

const DeleteAdmin = ({
  isOpen = false,
  onClose = () => {},
  dataAdmin = null,
  onRefreshTable = () => {},
}) => {
  const [error, setError] = useState("")
  const [success, setSuccess] = useState("")
  const [loading, setLoading] = useState()
  {
    error && error ? <Alert color="danger">Error: {error}</Alert> : null
  }

  const deleteAdmin = async id => {
    try {
      setLoading(true)
      const response = await deleteApiAdmin(id)
      setLoading(false)
      if (response.status === "SUCCESS") {
        onClose();
        onRefreshTable();
      } else {
        setError(response.message)
        setTimeout(() => {
          setError("")
        }, 5000)
      }
    } catch (error) {
       setLoading(false)
      setError("Something went wrong, please try again")
      setTimeout(() => {
        setError("")
      }, 5000)
    }
  }

  return (
    <Modal isOpen={isOpen}>
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
          Delete Admin
        </h5>
        <button
          type="button"
          onClick={loading ? () => {} : onClose}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        {error && error ? <Alert color="danger">Error: {error}</Alert> : null}

        <div className="text-center">
          <p className="h6 mb-4 d-block text-danger">
            Apakah kamu yakin akan menghapus admin?
          </p>
          <p className="h5 d-block">Nama : {dataAdmin?.name}</p>
          <p className="h5 d-block">Email : {dataAdmin?.email}</p>
        </div>
      </div>
      <div className="modal-footer">
        <Link to="#" className={`btn btn-secondary btn-block me-2 ${loading ? 'disabled' : ''}`} onClick={onClose}>
          Close
        </Link>
        <button className="btn btn-danger btn-block" onClick={() => deleteAdmin(dataAdmin?.id)}>
          {loading ? <Spinner size="sm" /> : "Delete"}
        </button>
      </div>
    </Modal>
  )
}

export default DeleteAdmin
