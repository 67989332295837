/* eslint-disable react/prop-types */
import InputEmail from "components/Input/InputEmail"
import InputPassword from "components/Input/InputPassword"
import InputText from "components/Input/InputText"
import { useFormik } from "formik"
import React, { useState } from "react"
import MetaTags from "react-meta-tags"
import { Link } from "react-router-dom"
import * as Yup from "yup"
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Modal,
  Container,
  Form,
  Spinner,
  Alert,
} from "reactstrap"
import {
  postApiAddAdmin,
  postApiUpdateAdmin,
} from "helpers/BackendHelper/kelola-admin-backend"

const FormAdmin = ({
  isOpen = false,
  onClose = () => {},
  onRefreshTable = () => {},
  dataAdmin = null,
}) => {
  const [error, setError] = useState("")
  const [success, setSuccess] = useState("")
  const [loading, setLoading] = useState()

  const _formik = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      name: dataAdmin?.name || "",
      email: dataAdmin?.email || "",
      password: "",
    },

    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter Your Name"),
      email: Yup.string().required("Please Enter Your Email"),
      password:
        !dataAdmin && Yup.string().required("Please Enter Your Password"),
    }),

    onSubmit: values => {
      values = {
        ...values,
        id_admin: dataAdmin?.id,
      }
      if (!loading) addAdmin(values)
    },
  })

  const addAdmin = async data => {
    try {
      setLoading(true)
      let response

      if (!dataAdmin) response = await postApiAddAdmin(data)
      else response = await postApiUpdateAdmin(data)

      setLoading(false)
      if (response.status === "SUCCESS") {
        onClose()
        onRefreshTable()
      } else {
        setError(response.message)
        setTimeout(() => {
          setError("")
        }, 5000)
      }
    } catch (error) {
      setLoading(false)
      setError("Something went wrong, please try again")
      setTimeout(() => {
        setError("")
      }, 5000)
    }
  }

  return (
    <Modal isOpen={isOpen}>
      <Form
        onSubmit={e => {
          e.preventDefault()
          _formik.handleSubmit()
          return false
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            Form Admin
          </h5>
          <button
            type="button"
            onClick={loading ? () => {} : onClose}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          {error && error ? <Alert color="danger">Error: {error}</Alert> : null}

          {/* NAME */}
          <div className="mb-3">
            <InputText
              label="Name"
              name="name"
              placeholder="Name"
              value={_formik.values.name || ""}
              onChange={_formik.handleChange}
              onBlur={_formik.handleBlur}
              errorMsg={_formik.errors.name}
              invalid={
                _formik.touched.name && _formik.errors.name ? true : false
              }
            />
          </div>

          {/* EMAIL */}
          {!dataAdmin && (
            <div className="mb-3">
              <InputEmail
                label="Email"
                name="email"
                placeholder="Email"
                value={_formik.values.email || ""}
                onChange={_formik.handleChange}
                onBlur={_formik.handleBlur}
                errorMsg={_formik.errors.email}
                invalid={
                  _formik.touched.email && _formik.errors.email ? true : false
                }
              />
            </div>
          )}

          {/* Password */}
          {!dataAdmin && (
            <div className="mb-3">
              <InputPassword
                label="Password"
                name="password"
                placeholder="Password"
                value={_formik.values.password || ""}
                onChange={_formik.handleChange}
                onBlur={_formik.handleBlur}
                errorMsg={_formik.errors.password}
                invalid={
                  _formik.touched.password && _formik.errors.password
                    ? true
                    : false
                }
              />
            </div>
          )}
        </div>
        <div className="modal-footer">
          <Link
            to="#"
            className={`btn btn-secondary btn-block me-2 ${
              loading ? "disabled" : ""
            }`}
            onClick={onClose}
          >
            Close
          </Link>

          <button className="btn btn-primary btn-block" type="submit">
            {loading ? <Spinner size="sm" /> : dataAdmin ? "Edit" : "Add"}
          </button>
        </div>
      </Form>
    </Modal>
  )
}

export default FormAdmin
