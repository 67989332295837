import BarChart from "components/echart/barchart"
import Doughnut from "components/echart/doughnutchart"
import LineBar from "components/echart/linebarchart"
import Pie from "components/echart/piechart"
import {
  getAPIBeasiswaRegister,
  getAPIDashboard,
} from "helpers/BackendHelper/beasiswa_register_backend"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  CardTitle,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

const Dashboard = props => {
  const [dataDetail, setDataDetail] = useState(null)

  useEffect(() => {
    getDashboard()
  }, [])

  const getDashboard = async id => {
    try {
      const response = await getAPIDashboard()
      if (response.status === "SUCCESS") {
        setDataDetail(response)
      } else {
      }
    } catch (error) {}
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Home | CMS - Beasiswa BUMN</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title="Beasiswa"
            breadcrumbItem="Home"
            titleMenu="Home"
          />

          <Row>
            <Col xl="12">
              <Row>
                <Col>
                  <Card className="mini-stats-wid">
                    <CardBody>
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <CardTitle className="text-muted fw-medium">
                            {" "}
                            Jumlah User Terdaftar
                          </CardTitle>

                          <h4 className="mb-0">
                            {dataDetail?.count_user || 0}
                          </h4>
                        </div>
                        <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                          <span className="avatar-title rounded-circle bg-primary">
                            <i className={"bx bx-group font-size-24"}></i>
                          </span>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>

                <Col>
                  <Card className="mini-stats-wid">
                    <CardBody>
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <CardTitle className="text-muted fw-medium">
                            {" "}
                            Jumlah User Mengajukan Beasiswa
                          </CardTitle>
                          <h4 className="mb-0">
                            {dataDetail?.count_beasiswa || 0}
                          </h4>
                        </div>
                        <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                          <span className="avatar-title rounded-circle bg-primary">
                            <i className={"bx bx-group font-size-24"}></i>
                          </span>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col lg="6">
              <Card>
                <CardBody>
                  <CardTitle>Kota Domisili</CardTitle>
                  <div id="asal-kota-chart" className="e-chart">
                    <BarChart
                      typeY="category"
                      labelY={dataDetail?.city.map(
                        e => e.city.charAt(0).toUpperCase() + e.city.slice(1)
                      )}
                      dataSeries={dataDetail?.city.map((e) => e.count)}
                      color="#3c4ccf"
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col lg="6">
              <Card>
                <CardBody>
                  <CardTitle className="text-muted fw-medium">Gender</CardTitle>
                  <div id="proporsi-chart" className="e-chart">
                    <Pie
                      name="Proporsi"
                      dataLegend={dataDetail?.gender.map(
                        e =>
                          e.gender.charAt(0).toUpperCase() + e.gender.slice(1)
                      )}
                      dataSeries={dataDetail?.gender.map(e => {
                        return {
                          value: e.count,
                          name:
                            e.gender.charAt(0).toUpperCase() +
                            e.gender.slice(1),
                        }
                      })}
                      colorItem={["#d5d5d5", "#3c4ccf", "#ec4561"]}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Dashboard
