import { del, get, post, put } from "../api_helper"
import * as url from "../url_helper"

const postApiUpdateProfile = data => {
  var formData = new FormData()
  formData.append("name", data.name)
  formData.append("photo", data.photo)

  return post(url.POST_UPDATE_PROFILE, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  })
}

const getApiUser = (offset, limit, search, sort) => {

  return get(url.GET_USER + `?offset=${offset}&limit=${limit}&search=${search}` + sort, {
    headers: { "Content-Type": "multipart/form-data" },
  })
}




export { postApiUpdateProfile, getApiUser  }
