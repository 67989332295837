import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import React, { useEffect, useState } from "react"
import {
  Row,
  Col,
  Alert,
  Card,
  CardBody,
  Container,
  FormFeedback,
  Input,
  Label,
  Form,
  Spinner,
} from "reactstrap"

//redux
import { useSelector, useDispatch } from "react-redux"

import { withRouter, Link } from "react-router-dom"

// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"

// action
import { userForgetPassword } from "../../store/actions"

// import images
import profile from "assets/images/logo-dark.png"
import { postApiValidasiEmail } from "helpers/backend_helper"

const ValidationEmailPage = props => {
  const [success, setSuccess] = useState("")
  const [error, setError] = useState("")

  useEffect(() => {
    let code = props
    validasiEmail(code.match.params.code)
  }, [])

  const validasiEmail = async code => {
    try {
      const response = await postApiValidasiEmail(code)
      if (response.status === "SUCCESS") {
        setSuccess("Email sudah terverivikasi silahkan login")
      } else {
        setError(response.message)
      }
    } catch (error) {
      setError("Something went wrong, please try again")
    }
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title>Forget Password | CMS - Beasiswa BUMN</title>
      </MetaTags>
      <div className="auth-full-bg">
        <div className="account-pages bg-overlay">
          <Container>
            <Row className="justify-content-center mt-5">
              <Col md={8} lg={6} xl={5}>
                <Card className="overflow-hidden card-login">
                  <div className="bg-primary bg-soft  p-2">
                    <Row>
                      <Col className="col-7">
                        <div className="text-primary p-4">
                          <h5 className="text-primary">Validasi Email</h5>
                        </div>
                      </Col>
                      <Col className="col-5 align-self-end">
                        <img src={profile} alt="" className="img-fluid" />
                      </Col>
                    </Row>
                  </div>
                  <CardBody className="pt-0">
                    <div className="p-2 mt-4">
                      <Form className="form-horizontal">
                        {success ? (
                          <Alert color="success" style={{ marginTop: "13px" }}>
                           Success: {success}
                          </Alert>
                        ) : (
                          <React.Fragment>
                            {error && error ? (
                              <Alert color="danger">Error: {error}</Alert>
                            ) : (
                              <div className="text-center">
                                <Spinner
                                  size={"sm"}
                                  className="d-inline-block me-2"
                                >
                                  Loading...
                                </Spinner>
                                <span className="text-center">
                                  Harap tunggu sedang memverifikasi email...
                                </span>
                              </div>
                            )}
                          </React.Fragment>
                        )}

                        {(success || error) && (
                          <div className="mt-4 text-center">
                            <p>
                              Kembali ke halaman
                              <Link
                                to="/login"
                                className="font-weight-medium text-primary"
                              >
                                {" "}
                                Login
                              </Link>{" "}
                            </p>
                          </div>
                        )}
                      </Form>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </React.Fragment>
  )
}

export default withRouter(ValidationEmailPage)
