import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import React from "react"
import {
  Row,
  Col,
  Alert,
  Card,
  CardBody,
  Container,
  FormFeedback,
  Input,
  Label,
  Form,
} from "reactstrap"

//redux
import { useSelector, useDispatch } from "react-redux"

import { withRouter, Link } from "react-router-dom"

// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"

// action
import { userForgetPassword } from "../../store/actions"

// import images
import profile from "assets/images/logo-dark.png"

const ForgetPasswordPage = props => {
  const dispatch = useDispatch()

  const _formik = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
    }),
    onSubmit: values => {
      dispatch(userForgetPassword(values))
    },
  })

  const { forgetError, forgetSuccessMsg } = useSelector(state => ({
    forgetError: state.ForgetPassword.forgetError,
    forgetSuccessMsg: state.ForgetPassword.forgetSuccessMsg,
  }))

  return (
    <React.Fragment>
      <MetaTags>
        <title>Forget Password | CMS - Beasiswa BUMN</title>
      </MetaTags>
      <div className="auth-full-bg">
        <div className="account-pages bg-overlay">
          <Container>
            <Row className="justify-content-center mt-5">
              <Col md={8} lg={6} xl={5}>
                <Card className="overflow-hidden card-login">
                  <div className="bg-primary bg-soft  p-2">
                    <Row>
                      <Col className="col-7">
                        <div className="text-primary p-4">
                          <h5 className="text-primary">Forgot Password</h5>
                        </div>
                      </Col>
                      <Col className="col-5 align-self-end">
                        <img src={profile} alt="" className="img-fluid" />
                      </Col>
                    </Row>
                  </div>
                  <CardBody className="pt-0">
                    <div className="p-2 mt-4">
                      {forgetError && forgetError ? (
                        <Alert color="danger" style={{ marginTop: "13px" }}>
                          Error: {forgetError.message}
                        </Alert>
                      ) : null}

                      <Form
                        className="form-horizontal"
                        onSubmit={e => {
                          e.preventDefault()
                          _formik.handleSubmit()
                          return false
                        }}
                      >
                        {forgetSuccessMsg ? (
                          <Alert color="success" style={{ marginTop: "13px" }}>
                            Email reset password telah dikirim ke:{" "}
                            {_formik.values.email}
                          </Alert>
                        ) : (
                          <React.Fragment>
                            <div className="mb-3">
                              <Label className="form-label">Email</Label>
                              <Input
                                name="email"
                                className="form-control"
                                placeholder="Enter email"
                                type="email"
                                onChange={_formik.handleChange}
                                onBlur={_formik.handleBlur}
                                value={_formik.values.email || ""}
                                invalid={
                                  _formik.touched.email && _formik.errors.email
                                    ? true
                                    : false
                                }
                              />
                              {_formik.touched.email && _formik.errors.email ? (
                                <FormFeedback type="invalid">
                                  {_formik.errors.email}
                                </FormFeedback>
                              ) : null}
                            </div>
                            <div className="mt-4 d-grid">
                              <button
                                className="btn btn-primary btn-block "
                                type="submit"
                              >
                                Reset
                              </button>
                            </div>
                          </React.Fragment>
                        )}

                        <div className="mt-4 text-center">
                          <p>
                            Kembali ke halaman
                            <Link
                              to="login"
                              className="font-weight-medium text-primary"
                            >
                               {" "}
                              Login
                            </Link>{" "}
                          </p>
                        </div>
                      </Form>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </React.Fragment>
  )
}

export default withRouter(ForgetPasswordPage)
