import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap"

import classnames from "classnames"
import { Link } from "react-router-dom"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import TabFormPersonalInformation from "./tabs/tab-form-personal-information"
import TabFormPhysicalDisabilities from "./tabs/tab-form-physical-disabilities"
import TabFormEmergencyContactInformation from "./tabs/tab-form-emergency-contact-information"
import TabFormEnglishLanguageProficiency from "./tabs/tab-form-english-language-proficiency"
import TabFormEducationalBackground from "./tabs/tab-form-educational-background"
import TabFormScholarshipHistory from "./tabs/tab-form-scholarship-history"
import TabFormCommunityServiceActivities from "./tabs/tab-form-community-service-activities"
import TabFormAwards from "./tabs/tab-form-awards"
import TabFormFieldOfStudy from "./tabs/tab-form-field-of-study"
import TabFormEssays from "./tabs/tab-form-essays"
import TabFormReferenceSummary from "./tabs/tab-form-reference-summary"
import TabFormRequiredDocuments from "./tabs/tab-form-required-documents"
import TabFormEmploymentHistory from "./tabs/tab-form-employment-history"
import TabFormPublications from "./tabs/tab-form-publications"
import TabFormProposedUniversity from "./tabs/tab-form-proposed_university"
import { getAPIBeasiswaRegister } from "helpers/BackendHelper/beasiswa_register_backend"
import { scrollIntoView, scrollTop } from "helpers/utils_helper"

const DetailPengajuanBeasiswa = (props) => {
  const [activeTabVertical, setoggleTabVertical] = useState(1)
  const [passedStepsVertical, setPassedStepsVertical] = useState([1])

  const [dataDetail, setDataDetail] = useState(null)
  const [industrialSector, setIndustrialSector] = useState(null)

  useEffect(() => {
    let _props = props
    getBeasiswaRegister(_props.match.params.id)
  }, [])

  const getBeasiswaRegister = async id => {
    try {
      const response = await getAPIBeasiswaRegister(id)
      if (response.status === "SUCCESS") {
        setDataDetail(response.data[0])
        setIndustrialSector(response.industrial_sector)
      } else {
      }
    } catch (error) {}
  }

  const onSuccess = message => {
    toast.success(message ? message : "Input Data Berhasil", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
    })
  }

  const tabsMenu = [
    {
      title: "PERSONAL INFORMATION",
      comp: (
        <TabFormPersonalInformation
          onPrev={null}
          onNext={() => toggleTabVertical(2)}
          onSuccess={onSuccess}
          dataDetail={dataDetail}
        />
      ),
    },
    {
      title: "PHYSICAL DISABILITIES",
      subtitle: "(if applicable)",
      comp: (
        <TabFormPhysicalDisabilities
          onPrev={() => toggleTabVertical(1)}
          onNext={() => toggleTabVertical(3)}
          onSuccess={onSuccess}
          dataDetail={dataDetail}
        />
      ),
    },
    {
      title: "EMERGENCY CONTACT INFORMATION",
      comp: (
        <TabFormEmergencyContactInformation
          onPrev={() => toggleTabVertical(2)}
          onNext={() => toggleTabVertical(4)}
          onSuccess={onSuccess}
          dataDetail={dataDetail}
        />
      ),
    },
    {
      title: "ENGLISH LANGUAGE PROFICIENCY",
      comp: (
        <TabFormEnglishLanguageProficiency
          onPrev={() => toggleTabVertical(3)}
          onNext={() => toggleTabVertical(5)}
          onSuccess={onSuccess}
          dataDetail={dataDetail}
        />
      ),
    },
    {
      title: "EDUCATIONAL BACKGROUND",
      comp: (
        <TabFormEducationalBackground
          onPrev={() => toggleTabVertical(4)}
          onNext={() => toggleTabVertical(6)}
          onSuccess={onSuccess}
          dataDetail={dataDetail}
        />
      ),
    },
    {
      title: "SCHOLARSHIP HISTORY",
      comp: (
        <TabFormScholarshipHistory
          onPrev={() => toggleTabVertical(5)}
          onNext={() => toggleTabVertical(7)}
          onSuccess={onSuccess}
          dataDetail={dataDetail}
        />
      ),
    },
    {
      title: "EMPLOYMENT HISTORY",
      comp: (
        <TabFormEmploymentHistory
          onPrev={() => toggleTabVertical(6)}
          onNext={() => toggleTabVertical(8)}
          onSuccess={onSuccess}
          dataDetail={dataDetail}
        />
      ),
    },
    {
      title: "COMMUNITY SERVICE ACTIVITIES",
      comp: (
        <TabFormCommunityServiceActivities
          onPrev={() => toggleTabVertical(7)}
          onNext={() => toggleTabVertical(9)}
          onSuccess={onSuccess}
          dataDetail={dataDetail}
        />
      ),
    },
    {
      title: "PUBLICATIONS",
      comp: (
        <TabFormPublications
          onPrev={() => toggleTabVertical(8)}
          onNext={() => toggleTabVertical(10)}
          onSuccess={onSuccess}
          dataDetail={dataDetail}
        />
      ),
    },
    {
      title: "AWARDS",
      comp: (
        <TabFormAwards
          onPrev={() => toggleTabVertical(9)}
          onNext={() => toggleTabVertical(11)}
          onSuccess={onSuccess}
          dataDetail={dataDetail}
        />
      ),
    },
    {
      title: "FIELD OF STUDY",
      comp: (
        <TabFormFieldOfStudy
          onPrev={() => toggleTabVertical(10)}
          onNext={() => toggleTabVertical(12)}
          onSuccess={onSuccess}
          dataDetail={dataDetail}
          industrialSector={industrialSector}
        />
      ),
    },
    {
      title: "PROPOSED UNIVERSITY",
      comp: (
        <TabFormProposedUniversity
          onPrev={() => toggleTabVertical(11)}
          onNext={() => toggleTabVertical(13)}
          onSuccess={onSuccess}
          dataDetail={dataDetail}
        />
      ),
    },
    {
      title: "ESSAYS",
      comp: (
        <TabFormEssays
          onPrev={() => toggleTabVertical(12)}
          onNext={() => toggleTabVertical(14)}
          onSuccess={onSuccess}
          dataDetail={dataDetail}
        />
      ),
    },
    {
      title: "REFERENCE SUMMARY",
      comp: (
        <TabFormReferenceSummary
          onPrev={() => toggleTabVertical(13)}
          onNext={() => toggleTabVertical(15)}
          onSuccess={onSuccess}
          dataDetail={dataDetail}
        />
      ),
    },
    {
      title: "REQUIRED DOCUMENTS",
      comp: (
        <TabFormRequiredDocuments
          onPrev={() => toggleTabVertical(14)}
          onNext={() => getBeasiswaRegister()}
          onSuccess={onSuccess}
          dataDetail={dataDetail}
          lastPage
        />
      ),
    },
  ]

  function toggleTabVertical(tab, type) {
    if (activeTabVertical !== tab) {
      scrollTop()

      var modifiedSteps = [...passedStepsVertical, tab]

      setoggleTabVertical(tab)
      setPassedStepsVertical(modifiedSteps)
    }
  }

  return (
    <React.Fragment>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable={false}
        pauseOnHover={false}
      />
      <div className="page-content">
        <MetaTags>
          <title>Detail Pengajuan Beasiswa | CMS - Beasiswa BUMN</title>
        </MetaTags>
        <Container fluid={true}>
          <Breadcrumbs
            title="Pengajuan Beasiswa"
            breadcrumbItem="Detail"
            titleMenu="Detail Pengajuan Beasiswa"
          />

        
          <React.Fragment>
            {/* DESKTOP */}
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <div className="vertical-wizard wizard clearfix vertical">
                      <div className="steps clearfix">
                        <ul>
                          {tabsMenu.map((tabmenu, index) => {
                            return (
                              <NavItem
                                key={index}
                                className={classnames({
                                  current: activeTabVertical === index + 1,
                                })}
                              >
                                <NavLink
                                  className={classnames({
                                    active: activeTabVertical === index + 1,
                                  })}
                                  onClick={() => {
                                    toggleTabVertical(index + 1)
                                  }}
                                >
                                  <div className="d-flex">
                                    <div>
                                      <span className="number">
                                        {index + 1}
                                      </span>
                                    </div>
                                    <div className="d-flex flex-column justify-content-center">
                                      <span> {tabmenu.title}</span>
                                      <span className="font-size-12">
                                        {" "}
                                        {tabmenu.subtitle}
                                      </span>
                                    </div>
                                  </div>
                                </NavLink>
                              </NavItem>
                            )
                          })}
                        </ul>
                      </div>
                      <div
                        className="content clearfix"
                        id="content-register-beasiswa"
                      >
                        <TabContent
                          activeTab={activeTabVertical}
                          className="body"
                        >
                          {tabsMenu.map((tabmenu, index) => {
                            return (
                              <TabPane key={index} tabId={index + 1}>
                                {tabmenu.comp}
                              </TabPane>
                            )
                          })}
                        </TabContent>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </React.Fragment>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default DetailPengajuanBeasiswa
