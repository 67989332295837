import React from "react"
import { Redirect } from "react-router-dom"

// Profile
import UserProfile from "../pages/Authentication/user-profile"


// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"
import ValidationEmailPage from "../pages/Authentication/validation-email"


// Dashboard
import Dashboard from "../pages/Dashboard/index"
import FormPengajuanBeasiswa from "pages/RegistrasiBeasiswa/FormPengajuanBeasiswa/form-pengajuan-beasiswa"
import StatusPengajuanBeasiswa from "pages/RegistrasiBeasiswa/StatusPengajuanBeasiswa/status-pangajuan-beasiswa-page"
import PengajuanBeasiswaPage from "pages/PengajuanBeasiswa/pengajuan-beasiswa-page"
import KelolaUserPage from "pages/KelolaUser/kelola-user-page"
import KelolaAdminPage from "pages/KelolaAdmin/kelola-admin-page"
import DetailPengajuanBeasiswa from "pages/PengajuanBeasiswa/detail-pengajuan-beasiswa-page"
import ScorePengajuanBeasiswa from "pages/PengajuanBeasiswa/score-pengajuan-beasiswa-page"

const authProtectedRoutes = [
  { path: "/home", component: Dashboard },

  // PROFILE
  { path: "/profile", component: UserProfile },

  // MENU CMS
  { path: "/pengajuan-beasiswa", component: PengajuanBeasiswaPage },
  { path: "/detail-pengajuan-beasiswa/:id", component: DetailPengajuanBeasiswa },
  { path: "/score-pengajuan-beasiswa/:id", component: ScorePengajuanBeasiswa },
  { path: "/kelola-user", component: KelolaUserPage },
  { path: "/kelola-admin", component: KelolaAdminPage },
  

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: () => <Redirect to="/home" /> },
]

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },
  { path: "/validation/:code", component: ValidationEmailPage},
]

export { publicRoutes, authProtectedRoutes }
